import { formatDate2 } from "../../functions/formatDate"
import CheptelSectionTitle from "../CheptelSectionTitle"
import "./Reproduction.scss"
import { useState } from "react"

const Reproduction = ({ animal }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow = window.localStorage.showrepro === "false" ? false : true
  let savedShowData =
    window.localStorage.showreprodata === "false" ? false : true
  let savedShowProduits =
    window.localStorage.showreproproduits === "false" ? false : true

  const [actualCol, setActualCol] = useState("enfant_danais")
  const [sens, setSens] = useState("down")

  const [show, setShow] = useState(savedShow)
  const [show2, setShow2] = useState(savedShowData)
  const [show3, setShow3] = useState(savedShowProduits)
  const [produits, setProduits] = useState(
    structuredClone(sortProduits(actualCol, false))
  )

  let classFemelle = animal.SEXBOV === "2" ? "repro_femelle" : ""

  ////////////////
  // useEffects //
  ////////////////

  ///////////////
  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showrepro = !show
  }
  const hideOrShow2 = () => {
    setShow2(!show2)
    window.localStorage.showreprodata = !show2
  }
  const hideOrShow3 = () => {
    setShow3(!show3)
    window.localStorage.showreproproduits = !show3
  }
  const goTo = (num, sexe, nom = "") => {
    window.location.hash = `animal|${num}`
  }
  function sortProduits(col, changeSens) {
    if (!animal?.produits) return []

    let sensTmp = sens

    if (col !== actualCol) {
      setActualCol(col)
      sensTmp = ""
    }

    if (changeSens) {
      if (sensTmp === "up") sensTmp = "down"
      else sensTmp = "up"

      setSens(sensTmp)
    }

    let tmpProduits = structuredClone(animal?.produits)

    switch (col) {
      case "num":
        if (sensTmp === "up") {
          tmpProduits.sort((a, b) =>
            +a.enfant_nunati.substring(a.enfant_nunati.length - 4) >
            +b.enfant_nunati.substring(b.enfant_nunati.length - 4)
              ? 1
              : +b.enfant_nunati.substring(b.enfant_nunati.length - 4) >
                +a.enfant_nunati.substring(a.enfant_nunati.length - 4)
              ? -1
              : 0
          )
        }
        if (sensTmp === "down") {
          tmpProduits.sort((b, a) =>
            +a.enfant_nunati.substring(a.enfant_nunati.length - 4) >
            +b.enfant_nunati.substring(b.enfant_nunati.length - 4)
              ? 1
              : +b.enfant_nunati.substring(b.enfant_nunati.length - 4) >
                +a.enfant_nunati.substring(a.enfant_nunati.length - 4)
              ? -1
              : 0
          )
        }
        break

      case "ponaiss":
        if (sensTmp === "up") {
          tmpProduits.sort((a, b) => {
            const frCollator = new Intl.Collator("fr")

            if (a.enfant_ponais && b.enfant_ponais) {
              return frCollator.compare(
                a.enfant_ponais ?? "",
                b.enfant_ponais ?? ""
              )
            } else if (a.enfant_ponais && !b.enfant_ponais) {
              return 1
            } else if (!a.enfant_ponais && b.enfant_ponais) {
              return -1
            } else if (a.enfant_toupoi && b.enfant_toupoi) {
              return frCollator.compare(
                a.enfant_toupoi ?? "",
                b.enfant_toupoi ?? ""
              )
            } else if (a.enfant_toupoi && !b.enfant_toupoi) {
              return 1
            } else if (!a.enfant_toupoi && b.enfant_toupoi) {
              return -1
            } else return 0
          })
        }
        if (sensTmp === "down") {
          tmpProduits.sort((b, a) => {
            const frCollator = new Intl.Collator("fr")

            if (a.enfant_ponais && b.enfant_ponais) {
              return frCollator.compare(
                a.enfant_ponais ?? "",
                b.enfant_ponais ?? ""
              )
            } else if (a.enfant_ponais && !b.enfant_ponais) {
              return 1
            } else if (!a.enfant_ponais && b.enfant_ponais) {
              return -1
            } else if (a.enfant_toupoi && b.enfant_toupoi) {
              return frCollator.compare(
                a.enfant_toupoi ?? "",
                b.enfant_toupoi ?? ""
              )
            } else if (a.enfant_toupoi && !b.enfant_toupoi) {
              return 1
            } else if (!a.enfant_toupoi && b.enfant_toupoi) {
              return -1
            } else return 0
          })
        }
        break
      case "num_parent":
        if (sensTmp === "up") {
          tmpProduits.sort((a, b) =>
            +a.num_parent.substring(a.num_parent.length - 4) >
            +b.num_parent.substring(b.num_parent.length - 4)
              ? 1
              : +b.num_parent.substring(b.num_parent.length - 4) >
                +a.num_parent.substring(a.num_parent.length - 4)
              ? -1
              : 0
          )
        }
        if (sensTmp === "down") {
          tmpProduits.sort((b, a) =>
            +a.num_parent.substring(a.num_parent.length - 4) >
            +b.num_parent.substring(b.num_parent.length - 4)
              ? 1
              : +b.num_parent.substring(b.num_parent.length - 4) >
                +a.num_parent.substring(a.num_parent.length - 4)
              ? -1
              : 0
          )
        }
        break

      default:
        const frCollator = new Intl.Collator("fr")

        if (sensTmp === "up") {
          tmpProduits.sort((a, b) =>
            frCollator.compare(a[col] ?? "", b[col] ?? "")
          )
        }
        if (sensTmp === "down") {
          tmpProduits.sort((b, a) =>
            frCollator.compare(a[col] ?? "", b[col] ?? "")
          )
        }
        break
    }

    return tmpProduits
  }
  const applySetProduits = col => {
    setProduits(sortProduits(col, true))
  }
  const getClassThProduit = col => {
    let classTh = ""

    if (actualCol === col) {
      if (sens === "up") classTh = "sortDown"
      else if (sens === "down") classTh = "sortUp"
    }

    return classTh
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  let divTopNotif = document.getElementById("div_topnotif_sw")
  let wrapNotif = document.getElementById("wrapper_notification_new_data")
  let wrapNotifConf = document.getElementById(
    "wrapper_notification_undone_confirm"
  )
  let wrapNotifDecl = document.getElementById(
    "wrapper_notification_undone_declare"
  )

  let needMargin = divTopNotif.classList.contains("show_notif")
    ? "needMargin"
    : ""

  if (!needMargin && wrapNotif)
    needMargin = wrapNotif.classList.contains("show") ? "needMargin" : ""
  if (!needMargin && wrapNotifConf)
    needMargin = wrapNotifConf.classList.contains("show") ? "needMargin" : ""
  if (!needMargin && wrapNotifDecl)
    needMargin = wrapNotifDecl.classList.contains("show") ? "needMargin" : ""

  if (needMargin) {
    divTopNotif.classList.remove("bg_topnotif_blue_sw")
    if (wrapNotif) wrapNotif.classList.remove("bg_notif_blue_sw")
    if (wrapNotifConf) wrapNotifConf.classList.remove("bg_notif_blue_sw")
    if (wrapNotifDecl) wrapNotifDecl.classList.remove("bg_notif_blue_sw")
  }

  return (
    <div id="repro_wrapper">
      {show ? (
        <CheptelSectionTitle
          title="Reproduction"
          onClick={hideOrShow}
          show={show}
          id="reproduction_title"
        />
      ) : (
        <div className={`reproduction ${needMargin}`}>
          <CheptelSectionTitle
            title="Reproduction"
            onClick={hideOrShow}
            show={show}
          />
        </div>
      )}
      {show && (
        <div
          className={`reproduction ${needMargin} ${classFemelle}`}
          id="reproduction"
        >
          {animal.SEXBOV === "2" && (
            <div id="div_reprodatarepro">
              <h3 onClick={hideOrShow2}>
                Données de reproduction{" "}
                <span className="showorhideicon">{show2 ? "-" : "+"}</span>
              </h3>
              {show2 && (
                <div className="reproduction_data" id="reprodatarepro">
                  <table
                    className="table_reproduction_data"
                    cellPadding="0"
                    cellSpacing="0"
                  >
                    <tbody>
                      <tr>
                        <td>Rang de vêlage</td>
                        <td>{animal.RADEVE}</td>
                      </tr>
                      <tr>
                        <td>
                          Age au 1<sup>er</sup> vêlage
                        </td>
                        <td>
                          {animal.AGE1VE &&
                            `${Math.floor(animal.AGE1VE / 30)} mois`}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Conditions 1<sup>er</sup> vêlage
                        </td>
                        <td>{animal.COV1VE}</td>
                      </tr>
                      <tr>
                        <td>Dernier vêlage</td>
                        <td>
                          {animal.DADEVE &&
                            animal.DADEVE !== "--" &&
                            animal.DADEVE}
                        </td>
                      </tr>
                      <tr>
                        <td>IVV moyen</td>
                        <td>
                          {animal.IVVMOY && (
                            <>
                              {`${animal.IVVMOY} jrs `}
                              <sub>
                                <i>{`(${animal.NIVVCA} pris en compte)`}</i>
                              </sub>
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>IVV 1-2</td>
                        <td>{animal.IVV1A2 && `${animal.IVV1A2} jrs`}</td>
                      </tr>
                      <tr>
                        <td>IVV 2+</td>
                        <td>{animal.IVV2PL && `${animal.IVV2PL} jrs`}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="baseref">{`Données calculées au ${animal.Adacalc}`}</div>
                </div>
              )}
            </div>
          )}

          <div id="div_reproproduits">
            {animal.produits.length === 0 ? (
              <div className="alert last_one">
                Cet animal n'a aucun produit.
              </div>
            ) : (
              <>
                <h3 onClick={hideOrShow3}>
                  {animal.produits.length === 300 ? (
                    <>
                      <i>300</i> derniers produits
                    </>
                  ) : (
                    <>
                      <i>
                        {animal.produits.length ? animal.produits.length : "0"}
                      </i>{" "}
                      produits
                    </>
                  )}
                  <span className="showorhideicon">{show3 ? "-" : "+"}</span>
                </h3>
                {show3 && (
                  <div className="produits" id="reproproduits">
                    <table>
                      <thead>
                        <tr>
                          <th
                            className={
                              getClassThProduit("num") + " pointer ripple2"
                            }
                            onClick={() => applySetProduits("num")}
                          >
                            Numéro
                          </th>
                          <th
                            className={
                              getClassThProduit("enfant_sexbov") +
                              " pointer ripple2"
                            }
                            onClick={() => applySetProduits("enfant_sexbov")}
                          >
                            Sexe
                          </th>
                          <th
                            className={
                              getClassThProduit("enfant_nobovi") +
                              " pointer ripple2"
                            }
                            onClick={() => applySetProduits("enfant_nobovi")}
                          >
                            Nom
                          </th>
                          <th
                            className={
                              getClassThProduit("enfant_danais") +
                              " pointer ripple2"
                            }
                            onClick={() => applySetProduits("enfant_danais")}
                          >
                            Date de <br />
                            naissance
                          </th>
                          <th
                            className={
                              getClassThProduit("ponaiss") + " pointer ripple2"
                            }
                            onClick={() => applySetProduits("ponaiss")}
                          >
                            Poids de naissance
                          </th>
                          <th
                            className={
                              getClassThProduit("inscr_pro") +
                              " pointer ripple2"
                            }
                            onClick={() => applySetProduits("inscr_pro")}
                          >
                            Inscr.
                          </th>
                          <th
                            className={
                              getClassThProduit("num_parent") +
                              " pointer ripple2"
                            }
                            onClick={() => applySetProduits("num_parent")}
                          >
                            {animal.produits[0]?.sexe_parent === "2"
                              ? "Mère"
                              : animal.produits[0]?.sexe_parent === "1" &&
                                "Père"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(produits).length !== 0 &&
                          produits?.map((produit, index) => (
                            <tr
                              key={
                                produit.enfant_copaip +
                                produit.enfant_nunati +
                                index
                              }
                            >
                              <td
                                className="underline pointer"
                                onClick={() =>
                                  goTo(
                                    produit.enfant_copaip +
                                      produit.enfant_nunati,
                                    produit.enfant_sexbov,
                                    produit.enfant_nobovi
                                  )
                                }
                              >
                                {produit.enfant_copaip +
                                  produit.enfant_nunati?.substring(
                                    0,
                                    produit.enfant_nunati?.length - 4
                                  )}
                                <span className="numtrav">
                                  {produit.enfant_nunati?.substring(
                                    produit.enfant_nunati?.length - 4
                                  )}
                                </span>
                              </td>
                              <td>
                                {produit.enfant_sexbov === "2" && (
                                  <div className="sexe femelle"></div>
                                )}
                                {produit.enfant_sexbov === "1" && (
                                  <div className="sexe male"></div>
                                )}
                              </td>
                              <td>{produit.enfant_nobovi}</td>
                              <td>
                                <nobr>
                                  {formatDate2(produit.enfant_danais)}
                                </nobr>
                              </td>
                              <td>
                                {produit.enfant_ponais !== ""
                                  ? produit.enfant_ponais
                                  : `${produit.enfant_toupoi} cm`}
                              </td>
                              <td>
                                <nobr>{produit.inscr_pro}</nobr>
                              </td>
                              <td
                                className="underline pointer"
                                onClick={() =>
                                  goTo(
                                    produit.code_parent + produit.num_parent,
                                    animal.produits[0]?.sexe_parent === 2
                                      ? "2"
                                      : animal.produits[0]?.sexe_parent === 1 &&
                                          "1"
                                  )
                                }
                              >
                                {produit.code_parent +
                                  produit.num_parent?.substring(
                                    0,
                                    produit.num_parent?.length - 4
                                  )}
                                <span className="numtrav">
                                  {produit.num_parent?.substring(
                                    produit.num_parent?.length - 4
                                  )}
                                </span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Reproduction
