import { useState } from "react"
import CheptelSectionTitle from "../CheptelSectionTitle"
import "./Genealogie.scss"
import { goBack } from "../../functions/handleNavigation"

const Genealogie = ({ animal, pageOption }) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let savedShow = window.localStorage.showgenealogie === "false" ? false : true
  const [show, setShow] = useState(savedShow)

  const [modalPere, setModalPere] = useState(true)
  pageOption === "pere" && !modalPere && setModalPere(true)
  pageOption !== "pere" && modalPere && setModalPere(false)
  const [modalMere, setModalMere] = useState(true)
  pageOption === "mere" && !modalMere && setModalMere(true)
  pageOption !== "mere" && modalMere && setModalMere(false)

  let aneeBaseRefPere
  if (animal?.Pannein) {
    aneeBaseRefPere = Math.round(animal.Pannein - 1)?.toString()
    aneeBaseRefPere = aneeBaseRefPere.substring(2, 4)
  }
  let rangBaseRefPere
  if (animal?.Prangin) {
    rangBaseRefPere = animal.Prangin?.toString()
    rangBaseRefPere = rangBaseRefPere.substring(1, 2)
  }
  let aneeBaseRefMere
  if (animal?.Mannein) {
    aneeBaseRefMere = Math.round(animal.Mannein - 1)?.toString()
    aneeBaseRefMere = aneeBaseRefMere.substring(2, 4)
  }
  let rangBaseRefMere
  if (animal?.Mrangin) {
    rangBaseRefMere = animal.Mrangin?.toString()
    rangBaseRefMere = rangBaseRefMere.substring(1, 2)
  }
  ////////////////
  // useEffects //
  ////////////////v
  ///////////////

  // Functions //
  ///////////////
  const hideOrShow = () => {
    setShow(!show)
    window.localStorage.showgenealogie = !show
  }
  const genalogieBulle = (nom, copaip, nunati, qualif, inscription, sexe) => {
    const classGenealogieBulle = sexe => {
      let cl = "genalogie-bulle"

      if (copaip && nunati) cl += " pointer"
      if (sexe === 2) cl += " femelle"
      else cl += " male"

      return cl
    }

    return (
      <div
        className={classGenealogieBulle(sexe)}
        onClick={() => copaip && nunati && linkToAnimal(copaip + nunati)}
      >
        {nom}
        <i> {qualif}</i>
        {inscription && inscription !== qualif && (
          <i className="qualifSecond">{" " + inscription}</i>
        )}
        <div className="numTravail">
          {copaip && nunati ? copaip + nunati : ""}
        </div>
      </div>
    )
  }
  const linkToAnimal = numAnimal => {
    window.location.hash = `animal|${numAnimal}`
  }
  const showModalPere = () => {
    window.location.hash = `animal_pere|${animal.COPAIP + animal.NUNATI}`
  }
  const showModalMere = () => {
    window.location.hash = `animal_mere|${animal.COPAIP + animal.NUNATI}`
  }

  return (
    <>
      <CheptelSectionTitle
        title="Généalogie"
        id="genealogie_title"
        onClick={hideOrShow}
        show={show}
      />

      {show && (
        <>
          {animal?.AanalyseVcgGenreCompat2p === "ok" && (
            <div className="infos-vcg">
              Génétiquement compatible avec les deux parents
            </div>
          )}
          {animal?.AanalyseVcgGenreCompat2p === "p" && (
            <div className="infos-vcg">
              Génétiquement compatible uniquement avec le père
            </div>
          )}
          {animal?.AanalyseVcgGenreCompat2p === "m" && (
            <div className="infos-vcg">
              Génétiquement compatible uniquement avec la mère
            </div>
          )}
          <div className="genalogie" id="genealogie">
            <table className="table_genealogie">
              <tbody>
                <tr>
                  {/* pere */}
                  <td rowSpan="4" className="td_pere">
                    {genalogieBulle(
                      animal?.Pnobovi,
                      animal?.Pcopaip,
                      animal?.Pnunati,
                      animal?.qualifPere,
                      animal?.inscrPere,
                      1
                    )}
                    {(animal.passage === "ok" || animal.passage === "forc") &&
                      (animal?.Pifnais ||
                        animal?.Pcrsevs ||
                        animal?.Pdmsevs ||
                        animal?.Pdssevs ||
                        animal?.Pfosevs ||
                        animal?.Pisevre ||
                        animal?.Pavelag ||
                        animal?.Palaits ||
                        animal?.Pivmate) && (
                        <>
                          <div className="table_index_parents">
                            <table className="table-index-parents">
                              <thead>
                                <tr>
                                  {animal?.Pifnais && <th>IFNAIS</th>}
                                  {animal?.Pcrsevs && <th>CRsev</th>}
                                  {animal?.Pdmsevs && <th>DMsev</th>}
                                  {animal?.Pdssevs && <th>DSsev</th>}
                                  {animal?.Pfosevs && <th>FOSsev</th>}
                                  {animal?.Pisevre && <th>ISEVR</th>}
                                  {animal?.Pavelag && <th>AVEL</th>}
                                  {animal?.Palaits && <th>ALait</th>}
                                  {animal?.Pivmate && <th>IVMAT</th>}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  {animal?.Pifnais && <td>{animal.Pifnais}</td>}
                                  {animal?.Pcrsevs && <td>{animal.Pcrsevs}</td>}
                                  {animal?.Pdmsevs && <td>{animal.Pdmsevs}</td>}
                                  {animal?.Pdssevs && <td>{animal.Pdssevs}</td>}
                                  {animal?.Pfosevs && <td>{animal.Pfosevs}</td>}
                                  {animal?.Pisevre && (
                                    <td className="pp">{animal.Pisevre}</td>
                                  )}
                                  {animal?.Pavelag && <td>{animal.Pavelag}</td>}
                                  {animal?.Palaits && <td>{animal.Palaits}</td>}
                                  {animal?.Pivmate && (
                                    <td className="pp">{animal.Pivmate}</td>
                                  )}
                                </tr>
                                <tr className="d">
                                  {animal?.Pifnais && <td>{animal.Pcdifna}</td>}
                                  {animal?.Pcrsevs && <td>{animal.Pcdcrse}</td>}
                                  {animal?.Pdmsevs && <td>{animal.Pcddmse}</td>}
                                  {animal?.Pdssevs && <td>{animal.Pcddsse}</td>}
                                  {animal?.Pfosevs && <td>{animal.Pcdfose}</td>}
                                  {animal?.Pisevre && (
                                    <td className="pp">{animal.Pcdisev}</td>
                                  )}
                                  {animal?.Pavelag && <td>{animal.Pcdavel}</td>}
                                  {animal?.Palaits && <td>{animal.Pcdalai}</td>}
                                  {animal?.Pivmate && (
                                    <td className="pp">{animal.Pcdivma}</td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="base_ref_parents">
                            <span>
                              {`Base de référence: LI.PF.${aneeBaseRefPere}.${rangBaseRefPere} `}
                            </span>
                            {animal.Ptypein === "G" && (
                              <span
                                className="ibovalG"
                                title="IBOVAL Génomique"
                              >
                                G
                              </span>
                            )}
                          </div>
                          <button
                            className="button-index"
                            onClick={showModalPere}
                          >
                            voir les index
                          </button>
                        </>
                      )}
                  </td>
                  {/* grand pere paternel */}
                  <td className="lineUnder" rowSpan="2">
                    {genalogieBulle(
                      animal?.GPPnobovi,
                      animal?.GPPcopaip,
                      animal?.GPPnunati,
                      animal?.qualifGPP,
                      animal?.inscrGPP,
                      1
                    )}
                  </td>
                  {/* pere grand pere paternel */}
                  <td className="lineUnder">
                    {genalogieBulle(
                      animal?.PGPPnobovi,
                      animal?.PGPPcopaip,
                      animal?.PGPPnunati,
                      animal?.qualifPGPP,
                      animal?.inscrPGPP,
                      1
                    )}
                  </td>
                </tr>
                <tr>
                  {/* mere grand pere paternel */}
                  <td className="lineUnder">
                    {genalogieBulle(
                      animal?.MGPPnobovi,
                      animal?.MGPPcopaip,
                      animal?.MGPPnunati,
                      animal?.qualifMGPP,
                      animal?.inscrMGPP,
                      2
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="lineUnder" rowSpan="2">
                    {" "}
                    {/* grand mere paternel */}
                    {genalogieBulle(
                      animal?.GMPnobovi,
                      animal?.GMPcopaip,
                      animal?.GMPnunati,
                      animal?.qualifGMP,
                      animal?.inscrGMP,
                      2
                    )}
                  </td>
                  <td className="lineUnder">
                    {" "}
                    {/* pere grand pere paternel */}
                    {genalogieBulle(
                      animal?.PGMPnobovi,
                      animal?.PGMPcopaip,
                      animal?.PGMPnunati,
                      animal?.qualifPGMP,
                      animal?.inscrPGMP,
                      1
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="lineUnder">
                    {" "}
                    {/* mere grand mere paternel */}
                    {genalogieBulle(
                      animal?.MGMPnobovi,
                      animal?.MGMPcopaip,
                      animal?.MGMPnunati,
                      animal?.qualifMGMP,
                      animal?.inscrMGMP,
                      2
                    )}
                  </td>
                </tr>
                <tr>
                  <td rowSpan="4">
                    {" "}
                    {/* mere */}
                    {genalogieBulle(
                      animal?.Mnobovi,
                      animal?.Mcopaip,
                      animal?.Mnunati,
                      animal?.qualifMere,
                      animal?.inscrMere,
                      2
                    )}
                    {(animal.passage === "ok" || animal.passage === "forc") &&
                      (animal?.Mifnais ||
                        animal?.Mcrsevs ||
                        animal?.Mdmsevs ||
                        animal?.Mdssevs ||
                        animal?.Mfosevs ||
                        animal?.Misevre ||
                        animal?.Mavelag ||
                        animal?.Malaits ||
                        animal?.Mivmate) && (
                        <>
                          <div className="table_index_parents">
                            <table className="table-index-parents">
                              <thead>
                                <tr>
                                  {animal?.Mifnais && <th>IFNAIS</th>}
                                  {animal?.Mcrsevs && <th>CRsev</th>}
                                  {animal?.Mdmsevs && <th>DMsev</th>}
                                  {animal?.Mdssevs && <th>DSsev</th>}
                                  {animal?.Mfosevs && <th>FOSsev</th>}
                                  {animal?.Misevre && <th>ISEVR</th>}
                                  {animal?.Mavelag && <th>AVEL</th>}
                                  {animal?.Malaits && <th>ALait</th>}
                                  {animal?.Mivmate && <th>IVMAT</th>}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  {animal?.Mifnais && <td>{animal.Mifnais}</td>}
                                  {animal?.Mcrsevs && <td>{animal.Mcrsevs}</td>}
                                  {animal?.Mdmsevs && <td>{animal.Mdmsevs}</td>}
                                  {animal?.Mdssevs && <td>{animal.Mdssevs}</td>}
                                  {animal?.Mfosevs && <td>{animal.Mfosevs}</td>}
                                  {animal?.Misevre && (
                                    <td className="pm">{animal.Misevre}</td>
                                  )}
                                  {animal?.Mavelag && <td>{animal.Mavelag}</td>}
                                  {animal?.Malaits && <td>{animal.Malaits}</td>}
                                  {animal?.Mivmate && (
                                    <td className="pm">{animal.Mivmate}</td>
                                  )}
                                </tr>
                                <tr className="d">
                                  {animal?.Mifnais && <td>{animal.Mcdifna}</td>}
                                  {animal?.Mcrsevs && <td>{animal.Mcdcrse}</td>}
                                  {animal?.Mdmsevs && <td>{animal.Mcddmse}</td>}
                                  {animal?.Mdssevs && <td>{animal.Mcddsse}</td>}
                                  {animal?.Mfosevs && <td>{animal.Mcdfose}</td>}
                                  {animal?.Misevre && (
                                    <td className="pm">{animal.Mcdisev}</td>
                                  )}
                                  {animal?.Mavelag && <td>{animal.Mcdavel}</td>}
                                  {animal?.Malaits && <td>{animal.Mcdalai}</td>}
                                  {animal?.Mivmate && (
                                    <td className="pm">{animal.Mcdivma}</td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="base_ref_parents">
                            <span>
                              {`Base de référence: LI.PF.${aneeBaseRefMere}.${rangBaseRefMere} `}
                            </span>
                            {animal.Mtypein === "G" && (
                              <span
                                className="ibovalG"
                                title="IBOVAL Génomique"
                              >
                                G
                              </span>
                            )}
                          </div>
                          <button
                            className="button-index"
                            onClick={showModalMere}
                          >
                            voir les index
                          </button>
                        </>
                      )}
                  </td>
                  <td className="lineUnder" rowSpan="2">
                    {" "}
                    {/* grand pere maternel */}
                    {genalogieBulle(
                      animal?.GPMnobovi,
                      animal?.GPMcopaip,
                      animal?.GPMnunati,
                      animal?.qualifGPM,
                      animal?.inscrGPM,
                      1
                    )}
                  </td>
                  <td className="lineUnder">
                    {" "}
                    {/* pere grand pere maternel */}
                    {genalogieBulle(
                      animal?.PGPMnobovi,
                      animal?.PGPMcopaip,
                      animal?.PGPMnunati,
                      animal?.qualifPGPM,
                      animal?.inscrPGPM,
                      1
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="lineUnder">
                    {" "}
                    {/* mere grand pere maternel */}
                    {genalogieBulle(
                      animal?.MGPMnobovi,
                      animal?.MGPMcopaip,
                      animal?.MGPMnunati,
                      animal?.qualifMGPM,
                      animal?.inscrMGPM,
                      2
                    )}
                  </td>
                </tr>
                <tr>
                  <td rowSpan="2">
                    {" "}
                    {/* grand mere maternel */}
                    {genalogieBulle(
                      animal?.GMMnobovi,
                      animal?.GMMcopaip,
                      animal?.GMMnunati,
                      animal?.qualifGMM,
                      animal?.inscrGMM,
                      2
                    )}
                  </td>
                  <td className="lineUnder">
                    {" "}
                    {/* pere grand mere maternel */}
                    {genalogieBulle(
                      animal?.PGMMnobovi,
                      animal?.PGMMcopaip,
                      animal?.PGMMnunati,
                      animal?.qualifPGMM,
                      animal?.inscrPGMM,
                      1
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {/* mere grand mere maternel */}
                    {genalogieBulle(
                      animal?.MGMMnobovi,
                      animal?.MGMMcopaip,
                      animal?.MGMMnunati,
                      animal?.qualifMGMM,
                      animal?.inscrMGMM,
                      2
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
      {
        /* modal index pere */
        modalPere && (
          <>
            <div className="modal-index">
              <div className="div_modal_header">
                <span
                  onClick={goBack}
                  className="close_modal ripple2  material-symbols-outlined"
                >
                  close
                </span>
                <h3>{`${animal?.Pnobovi} - ${animal?.Pcopaip}${animal?.Pnunati}`}</h3>
              </div>

              <div className="modal-content">
                <table className="table-index-parents">
                  <thead>
                    <tr>
                      {animal?.Pifnais && <th>IFNAIS</th>}
                      {animal?.Pcrsevs && <th>CRsev</th>}
                      {animal?.Pdmsevs && <th>DMsev</th>}
                      {animal?.Pdssevs && <th>DSsev</th>}
                      {animal?.Pfosevs && <th>FOSsev</th>}
                      {animal?.Pisevre && <th>ISEVR</th>}
                      {animal?.Pavelag && <th>AVEL</th>}
                      {animal?.Palaits && <th>ALait</th>}
                      {animal?.Pivmate && <th>IVMAT</th>}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {animal?.Pifnais && <td>{animal.Pifnais}</td>}
                      {animal?.Pcrsevs && <td>{animal.Pcrsevs}</td>}
                      {animal?.Pdmsevs && <td>{animal.Pdmsevs}</td>}
                      {animal?.Pdssevs && <td>{animal.Pdssevs}</td>}
                      {animal?.Pfosevs && <td>{animal.Pfosevs}</td>}
                      {animal?.Pisevre && (
                        <td className="pp">{animal.Pisevre}</td>
                      )}
                      {animal?.Pavelag && <td>{animal.Pavelag}</td>}
                      {animal?.Palaits && <td>{animal.Palaits}</td>}
                      {animal?.Pivmate && (
                        <td className="pp">{animal.Pivmate}</td>
                      )}
                    </tr>
                    <tr className="d">
                      {animal?.Pifnais && <td>{animal.Pcdifna}</td>}
                      {animal?.Pcrsevs && <td>{animal.Pcdcrse}</td>}
                      {animal?.Pdmsevs && <td>{animal.Pcddmse}</td>}
                      {animal?.Pdssevs && <td>{animal.Pcddsse}</td>}
                      {animal?.Pfosevs && <td>{animal.Pcdfose}</td>}
                      {animal?.Pisevre && (
                        <td className="pp">{animal.Pcdisev}</td>
                      )}
                      {animal?.Pavelag && <td>{animal.Pcdavel}</td>}
                      {animal?.Palaits && <td>{animal.Pcdalai}</td>}
                      {animal?.Pivmate && (
                        <td className="pp">{animal.Pcdivma}</td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="base-ref">
                <span>
                  {`Base de référence: LI.PF.${aneeBaseRefPere}.${rangBaseRefPere} `}
                </span>
                {animal?.Ptypein === "G" && (
                  <span className="ibovalG" title="IBOVAL Génomique">
                    G
                  </span>
                )}
              </div>
            </div>
            <button className="background-modal" onClick={goBack}></button>
          </>
        )
      }
      {
        /* modal index mere */
        modalMere && (
          <>
            <div className="modal-index">
              <div className="div_modal_header">
                <span
                  onClick={goBack}
                  className="close_modal ripple2  material-symbols-outlined"
                >
                  close
                </span>
                <h3>{`${animal?.Mnobovi} - ${animal?.Mcopaip}${animal?.Mnunati}`}</h3>
              </div>
              <div className="modal-content">
                <table className="table-index-parents">
                  <thead>
                    <tr>
                      {animal?.Mifnais && <th>IFNAIS</th>}
                      {animal?.Mcrsevs && <th>CRsev</th>}
                      {animal?.Mdmsevs && <th>DMsev</th>}
                      {animal?.Mdssevs && <th>DSsev</th>}
                      {animal?.Mfosevs && <th>FOSsev</th>}
                      {animal?.Misevre && <th>ISEVR</th>}
                      {animal?.Mavelag && <th>AVEL</th>}
                      {animal?.Malaits && <th>ALait</th>}
                      {animal?.Mivmate && <th>IVMAT</th>}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {animal?.Mifnais && <td>{animal.Mifnais}</td>}
                      {animal?.Mcrsevs && <td>{animal.Mcrsevs}</td>}
                      {animal?.Mdmsevs && <td>{animal.Mdmsevs}</td>}
                      {animal?.Mdssevs && <td>{animal.Mdssevs}</td>}
                      {animal?.Mfosevs && <td>{animal.Mfosevs}</td>}
                      {animal?.Misevre && (
                        <td className="pm">{animal.Misevre}</td>
                      )}
                      {animal?.Mavelag && <td>{animal.Mavelag}</td>}
                      {animal?.Malaits && <td>{animal.Malaits}</td>}
                      {animal?.Mivmate && (
                        <td className="pm">{animal.Mivmate}</td>
                      )}
                    </tr>
                    <tr className="d">
                      {animal?.Mifnais && <td>{animal.Mcdifna}</td>}
                      {animal?.Mcrsevs && <td>{animal.Mcdcrse}</td>}
                      {animal?.Mdmsevs && <td>{animal.Mcddmse}</td>}
                      {animal?.Mdssevs && <td>{animal.Mcddsse}</td>}
                      {animal?.Mfosevs && <td>{animal.Mcdfose}</td>}
                      {animal?.Misevre && (
                        <td className="pm">{animal.Mcdisev}</td>
                      )}
                      {animal?.Mavelag && <td>{animal.Mcdavel}</td>}
                      {animal?.Malaits && <td>{animal.Mcdalai}</td>}
                      {animal?.Mivmate && (
                        <td className="pm">{animal.Mcdivma}</td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="base-ref">
                <span>
                  {`Base de référence: LI.PF.${aneeBaseRefMere}.${rangBaseRefMere} `}
                </span>
                {animal?.Mtypein === "G" && (
                  <span className="ibovalG" title="IBOVAL Génomique">
                    G
                  </span>
                )}
              </div>
            </div>
            <button className="background-modal" onClick={goBack}></button>
          </>
        )
      }
    </>
  )
}

export default Genealogie
