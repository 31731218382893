import { useEffect, useRef, useState } from "react"
import "./AnimalHeader.scss"
import localforage from "localforage"
import { fetchGetTsPhoto } from "../../functions/fetches/fetchGetTsPhoto"
import { goToHashWithParams } from "../../functions/hash"
import ModalPhoto from "../ModalPhoto"

const AnimalHeader = ({
  dataCalculated,
  animal,
  havePicture,
  pageOption,
  getParcours,
}) => {
  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  let numCheptel = window.localStorage.numCheptel ?? ""

  let valA = window.localStorage.valA === "true" ? true : false

  const [imgClass, setImgClass] = useState("noDisplay")

  const [showModalPhoto, setShowModalPhoto] = useState(false)
  pageOption === "photo" && !showModalPhoto && setShowModalPhoto(true)
  pageOption !== "photo" && showModalPhoto && setShowModalPhoto(false)

  const divImgStation = useRef()
  const cardAnimal = useRef()
  const divLeftPart = useRef()
  const divHeaderAnimal = useRef()
  const imgPhoto = useRef()

  let urlPhoto = `${process.env.REACT_APP_ESPRO_PHOTOS_FICHE_URL}${
    animal?.COPAIP + animal?.NUNATI
  }_${animal?.ts_photos}.jpg`

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    getTsPhoto()
  }, [])
  ///////////////
  // Functions //
  ///////////////
  const imgLoaded = () => {
    setImgClass("pointer")

    divImgStation?.current?.classList?.add("display")
    divLeftPart?.current?.classList?.add("with_width")
    divHeaderAnimal?.current?.classList?.add("one_sided")
    cardAnimal?.current?.classList?.add("two_side")

    havePicture.current = true

    let height = imgPhoto.current.height
    let width = imgPhoto.current.width

    animal.photoWidth = width
    animal.photoHeight = height
  }
  const getTsPhoto = async () => {
    let ts = await fetchGetTsPhoto(animal?.COPAIP + animal?.NUNATI)

    if (ts === "" || +ts !== 0) {
      animal.ts_photos = ts
      if (imgPhoto.current) {
        imgPhoto.current.src = `${
          process.env.REACT_APP_ESPRO_PHOTOS_FICHE_URL
        }${animal?.COPAIP + animal?.NUNATI}_${animal?.ts_photos}.jpg`
      }

      let dataSuffix = window.localStorage.dataSuffix ?? ""
      let numCheptel = window.localStorage.numCheptel ?? ""

      localforage.setItem(
        "dataCalculated" + dataSuffix + numCheptel,
        dataCalculated
      )
    }
  }
  const imgOnClick = () => {
    if ("ReactNativeWebView" in window) {
      var newImg = new Image()

      newImg.onload = function () {
        // Create a canvas element
        const canvas = document.createElement("canvas")
        // Set the canvas dimensions to the image dimensions
        canvas.width = newImg.width
        canvas.height = newImg.height
        // Get the canvas context
        const ctx = canvas.getContext("2d")
        // Draw the image on the canvas
        ctx.drawImage(newImg, 0, 0)
        // Get the Base64-encoded data
        const dataURL = canvas.toDataURL()

        let message = {
          type: "ImageB64",
          content: {
            title: `${animal.COPAIP + animal.NUNATI} - ${animal.NOBOVI}`,
            b64: dataURL,
          },
        }

        let stringified = JSON.stringify(message)

        window.ReactNativeWebView.postMessage(stringified)
      }

      newImg.crossOrigin = "*"
      newImg.src = imgPhoto.current.src
    } else {
      goToHashWithParams("animal_photo")
    }
  }
  ////////////////
  // EXTRA CODE //
  ////////////////

  return (
    <div className="animal-header" id="animal_header" ref={cardAnimal}>
      {showModalPhoto && (
        <ModalPhoto
          url={urlPhoto}
          title={animal.NOBOVI + " - " + animal.COPAIP + animal.NUNATI}
        />
      )}
      {animal.passage === "forc" && (
        <div className="alert">
          Vous avez accès à certaines performances de cet animal parce qu'il a
          produit dans votre cheptel
        </div>
      )}
      <div className="area_picture" ref={divImgStation}>
        <div className="img_ani_station">
          <img
            crossOrigin="*"
            ref={imgPhoto}
            id="photo_fiche_animal"
            className={imgClass}
            src={urlPhoto}
            onLoad={imgLoaded}
            onClick={imgOnClick}
          />
        </div>
      </div>
      <div className="area_infos" ref={divLeftPart}>
        <div className="titre">
          <h2
            className={
              animal.SEXBOV === "2" ? "nom sexe-femelle" : "nom sexe-male"
            }
          >
            <span className="nom_animal">{animal.NOBOVI}</span>
            <i
              className="aboqual"
              title={
                animal.qualifAnimalLIDETA
                  ? animal.qualifAnimalLIDETA
                  : animal.inscrAnimalLIDETA && animal.inscrAnimalLIDETA
              }
            >
              {animal.qualifAnimal
                ? animal.qualifAnimal
                : animal.inscrAnimal && animal.inscrAnimal}
            </i>
          </h2>
          <h3 className="numtravail">{animal.COPAIP + animal.NUNATI}</h3>
        </div>

        <div className="div_header_animal" ref={divHeaderAnimal}>
          <div className="infos-niassance">
            <p>Date de naissance :</p>
            <p>{`${animal.DANAIS} (${animal.age_format ?? ""})`}</p>
            {(valA ||
              (!valA &&
                numCheptel &&
                animal.COPCNA + animal.NUCHNA != numCheptel)) && (
              <>
                <p>
                  Cheptel de naissance{" "}
                  {valA &&
                  animal.detenteurNum !== "" &&
                  animal.detenteurNum === animal.COPCNA + animal.NUCHNA
                    ? "et détenteur :"
                    : " :"}
                </p>
                <p className="cheptel_selectable">
                  {animal.naisseurTxt} <br /> {animal.COPCNA + animal.NUCHNA}
                </p>
              </>
            )}
            <div></div>
            {valA && (
              <button
                className="btn_action ripple6 pointer"
                onClick={getParcours}
              >
                Voir son parcours
              </button>
            )}
            {valA &&
              animal.detenteurNum !== "" &&
              animal.detenteurNum !== animal.COPCNA + animal.NUCHNA && (
                <>
                  <p>Cheptel détenteur :</p>
                  <p className="cheptel_selectable">
                    {animal.detenteurNom}
                    <br />
                    {animal.detenteurNum}
                  </p>
                </>
              )}
          </div>
          {(animal.passage === "ok" || animal.passage === "forc") && (
            <div className="table-header">
              {(animal.PONAIS !== "" ||
                animal.bopoat120 !== "" ||
                animal.bopoat210 !== "") && (
                <table>
                  <thead>
                    <tr>
                      <th>Poids de naissance</th>
                      <th>{animal.PONAIS}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {animal.bopoat120 !== "" && (
                      <tr>
                        <td>à 120 jours</td>
                        <td className="right">{`${animal.bopoat120} kg`}</td>
                      </tr>
                    )}
                    {animal.bopoat210 !== "" && (
                      <tr>
                        <td>à 210 jours</td>
                        <td className="right">{`${animal.bopoat210} kg`}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}

              {(animal.bopoalDevmus !== "" ||
                animal.bopoalDevsqe !== "" ||
                animal.bopoalAptfon !== "" ||
                animal.bopoalNoetpo !== "") && (
                <table>
                  <thead>
                    <tr>
                      <th>DM</th>
                      <th>DS</th>
                      <th>AF</th>
                      <th>Etat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="center">{animal.bopoalDevmus}</td>
                      <td className="center">{animal.bopoalDevsqe}</td>
                      <td className="center">{animal.bopoalAptfon}</td>
                      <td className="center">{animal.bopoalNoetpo}</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AnimalHeader
