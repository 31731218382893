import { useEffect, useRef, useState } from "react"
import {
  formatDate,
  formatDate2,
  formatDate4,
} from "../../functions/formatDate"
import "./CustomInventaire.scss"
import { getColumns, getRFQUAL } from "../../functions/objectColums"
import ModalFiliation from "./modals/ModalFiliation"
import ModalEvalim from "./modals/ModalEvalim"
import ModalErreursConf from "./modals/ModalErreursConf"
import ModalAskConfirm from "./modals/ModalAskConfirm"
import ModalLoader from "../ModalLoader"
import { useCustomAlert } from "../../hooks/useCustomAlert"
import Alert from "../Alert"
import ModalResultConfirm from "./modals/ModalResultConfirm"
import ModalAskDeclaration from "./modals/ModalAskDeclaration"
import localforage from "localforage"
import ModalResultDeclar from "./modals/ModalResultDeclar"
import { declareLanaud } from "../../functions/fetches/declareLanaud"
import { fetchGoConfirm } from "../../functions/fetches/fetchGoConfirm"
import { checkInternet } from "../../functions/checkInternet"
import { blobToBase64 } from "../../functions/fetches/fetch"
import { goBack } from "../../functions/handleNavigation"
import { todayDate } from "../../functions/todayDate"

const NB_MAX_ANI_COMPARABLE = 15

let dataErreurs = {}
let clickHeld = false
let hasMoved = false
let mouseX = 0
let mouseY = 0
let originMouseX = 0
let originMouseY = 0
let resultConfirmations = []
let lastIdentConfirm = ""
let libelleSerie = ""
let aniToDeclare = []
let messageDeclaration = {}

const CustomInventaire = ({
  setDataCalculated,
  customInventory,
  dataSorted,
  allData,
  sortColumn,
  numTravail,
  colConfirm,
  colDeclar,
  colCompare,
  classColums,
  pageOption,
}) => {
  let dataSuffix = window.localStorage.dataSuffix ?? ""
  let numCheptel = window.localStorage.numCheptel ?? ""
  let aniData = null

  let numAni = window.location.hash.split("|")[1] ?? null

  if (numAni) {
    allData.forEach(
      ani => numAni === ani.COPAIP + ani.NUNATI && (aniData = ani)
    )
  }

  let customNum = customInventory?.num
  let action = customInventory?.action ?? null
  let liveFilters = JSON.parse(localStorage.liveFilters ?? "{}")

  let columns = getColumns()
  let seriesLanaud = window.localStorage.seriesLanaud ?? "[]"
  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )

  let infosAni = {}
  dataSorted.forEach(ani => {
    let num = ani.COPAIP + ani.NUNATI

    infosAni[num] = {
      SEXBOV: ani.SEXBOV,
      COFGMU: ani.COFGMU,
      NOBOVI: ani.NOBOVI,
    }
  })

  ///////////
  // HOOKS //
  ///////////
  // Handle display modalFiliation
  const [modalFiliation, setModalFiliation] = useState(false)
  pageOption === "filiation" && !modalFiliation && setModalFiliation(true)
  pageOption !== "filiation" && modalFiliation && setModalFiliation(false)
  // Handle display modalEvalim
  const [modalEvalim, setModalEvalim] = useState(false)
  pageOption === "evalim" && !modalEvalim && setModalEvalim(true)
  pageOption !== "evalim" && modalEvalim && setModalEvalim(false)
  // Handle display modalErreursConf
  const [modalErreursConf, setModalErreursConf] = useState(false)
  pageOption === "erreursconf" && !modalErreursConf && setModalErreursConf(true)
  pageOption !== "erreursconf" && modalErreursConf && setModalErreursConf(false)
  modalErreursConf &&
    Object.keys(dataErreurs).length === 0 &&
    (window.location.hash = "")

  const [casesCochees, setCasesCochees] = useState(
    JSON.parse(localStorage.caseConfirm ?? "[]")
  )
  const [casesCocheesDecl, setCasesCocheesDecl] = useState(
    JSON.parse(localStorage.caseDeclar ?? "[]")
  )
  const [optionsDeclCochees, setOptionsDeclCochees] = useState(
    JSON.parse(window.localStorage.optionsDeclCochees ?? "{}")
  )
  const [casesCocheesCompare, setCasesCocheesCompare] = useState(
    JSON.parse(localStorage["caseCompare" + customNum] ?? "[]")
  )
  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert()
  const tableInv = useRef()
  const [modalAskConfirm, setModalAskConfirm] = useState(false)
  const [modalResultConfirm, setModalResultConfirm] = useState(false)
  pageOption === "goconfirm" &&
    !modalAskConfirm &&
    !modalResultConfirm &&
    setModalAskConfirm(true)
  pageOption !== "goconfirm" && modalAskConfirm && setModalAskConfirm(false)
  pageOption !== "goconfirm" &&
    modalResultConfirm &&
    setModalResultConfirm(false)
  const [modalAskDeclar, setModalAskDeclar] = useState(false)
  const [modalResultDeclar, setModalResultDeclar] = useState(false)
  pageOption === "declarations" &&
    !modalAskDeclar &&
    !modalResultDeclar &&
    setModalAskDeclar(true)
  pageOption !== "declarations" && modalAskDeclar && setModalAskDeclar(false)
  pageOption !== "declarations" &&
    modalResultDeclar &&
    setModalResultDeclar(false)

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    footerPositioning()
    let el = tableInv.current

    el.addEventListener("footerPositioning", footerPositioning)

    return () => {
      el.removeEventListener("footerPositioning", footerPositioning)
    }
  }, [])
  useEffect(() => {
    let isNotifUpd = document
      .getElementById("div_topnotif_sw")
      .classList.contains("show_notif")
    let isNotifData = document
      .getElementById("wrapper_notification_new_data")
      .classList.contains("show")
    let isNotifConf = document
      .getElementById("wrapper_notification_undone_confirm")
      .classList.contains("show")
    let isNotifDecl = document
      .getElementById("wrapper_notification_undone_declare")
      .classList.contains("show")

    let invFooter = document.getElementById("inventaire_footer")
    let invTable = document.getElementById("inventaire_table")

    if (isNotifUpd || isNotifData || isNotifConf || isNotifDecl) {
      if (action === "confirm") {
        if (casesCochees.length !== 0) {
          invFooter.classList.remove("diff_bottom")
          invTable.classList.remove("diff_bottom")
        } else {
          invFooter.classList.add("diff_bottom")
          invTable.classList.add("diff_bottom")
        }
      }
      if (action === "declar") {
        if (casesCocheesDecl.length !== 0) {
          invFooter.classList.remove("diff_bottom")
          invTable.classList.remove("diff_bottom")
        } else {
          invFooter.classList.add("diff_bottom")
          invTable.classList.add("diff_bottom")
        }
      }
      if (action === "compare") {
        if (casesCocheesCompare.length !== 0) {
          invFooter.classList.remove("diff_bottom")
          invTable.classList.remove("diff_bottom")
        } else {
          invFooter.classList.add("diff_bottom")
          invTable.classList.add("diff_bottom")
        }
      }
    } else {
      invFooter.classList.remove("diff_bottom")
      invTable.classList.remove("diff_bottom")
    }
  }, [casesCochees, casesCocheesDecl, casesCocheesCompare])
  ///////////////
  // FUNCTIONS //
  ///////////////
  // Simple redirection
  const goThere = linkTo => {
    if (!hasMoved) window.location.hash = linkTo
  }
  const showErreurs = animal => {
    dataErreurs = {
      num: animal.COPAIP + animal.NUNATI,
      nom: animal.NOBOVI,
      erreurs: animal.erreurs,
    }

    if (!hasMoved) window.location.hash = "inventaire_erreursconf"
  }
  // Method to handle the footer positioning
  const footerPositioning = () => {
    let divTopNotif = document.getElementById("div_topnotif_sw")
    let wrapNotif = document.getElementById("wrapper_notification_new_data")
    let wrapNotifConf = document.getElementById(
      "wrapper_notification_undone_confirm"
    )
    let wrapNotifDecl = document.getElementById(
      "wrapper_notification_undone_declare"
    )
    let isNotifUpd = divTopNotif.classList.contains("show_notif")
    let isNotifData = wrapNotif.classList.contains("show")
    let isNotifConf = wrapNotifConf.classList.contains("show")
    let isNotifDecl = wrapNotifDecl.classList.contains("show")
    let invFooter = document.getElementById("inventaire_footer")
    let invTable = document.getElementById("inventaire_table")

    if (isNotifUpd || isNotifData || isNotifConf || isNotifDecl) {
      invFooter?.classList.add("diff_bottom")
      invTable?.classList.add("diff_bottom")

      divTopNotif.classList.add("bg_topnotif_blue_sw")
      if (wrapNotif) wrapNotif.classList.add("bg_notif_blue_sw")
      if (wrapNotifConf) wrapNotifConf.classList.add("bg_notif_blue_sw")
      if (wrapNotifDecl) wrapNotifDecl.classList.add("bg_notif_blue_sw")
    } else {
      invFooter?.classList.remove("diff_bottom")
      invTable?.classList.remove("diff_bottom")
    }
  }
  const showCertificate = async animal => {
    if (
      !hasMoved &&
      animal?.A_boqual_COQUBO !== "04" &&
      animal?.A_boqual_COQUBO !== "ST"
    ) {
      let divModal = document.getElementById("loader_certificate")
      if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

      let isOnline = await checkInternet()

      if (isOnline) {
        let link = `${process.env.REACT_APP_ESPRO_PDF_URL}espace-pro/hbl/certificat/${animal.COPAIP}/${animal.NUNATI}-F`

        if (isMobile) {
          let ts = Math.round(Date.now() / 1000)
          let name = `certificat_${animal.COPAIP}${animal.NUNATI}_${ts}.pdf`

          await fetch(link)
            .then(response => response.blob())
            .then(blob => {
              if ("ReactNativeWebView" in window) {
                blobToBase64(blob).then(res => {
                  let message = {
                    type: "PDFB64",
                    content: {
                      title: name,
                      b64: res,
                    },
                  }

                  let stringified = JSON.stringify(message)

                  window.onLeavePdfViewer = () => {
                    if (divModal)
                      divModal.dispatchEvent(new CustomEvent("hideModal"))

                    window.onLeavePdfViewer = null
                  }

                  window.ReactNativeWebView.postMessage(stringified)
                })
              } else {
                var url = window.URL.createObjectURL(blob)
                var a = document.createElement("a")
                a.href = url
                // a.target = "_blank"
                a.download = name
                document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click()
                a.remove() //afterwards we remove the element again

                if (divModal)
                  divModal.dispatchEvent(new CustomEvent("hideModal"))
              }
            })
            .catch(error => {
              console.log(error)
              doAlert("Une erreur est survenu lors de la récuération du PDF.")

              if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
            })
        } else {
          window.open(link)

          if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
        }
      } else {
        if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))

        doAlert(
          "Problème de connexion avec le serveur. Veuillez vérifier votre connexion internet et réessayer s.v.p."
        )
      }
    }
  }
  /////////////////////////////////////////////////////////
  // Methods to handle the "CONFIRMATION" functionnality //
  /////////////////////////////////////////////////////////
  const cocheeCase = animal => {
    if (animal.reponse === "non") return

    let valO = window.localStorage.valO === "true" ? true : false

    if (valO) {
      doAlert("Vous n'avez pas le droit de confirmer des animaux.")
      return
    }

    let nAni = animal.COPAIP + animal.NUNATI
    let laCase = document.getElementById("case" + nAni)
    let arr = casesCochees
    let listCouldntTry = JSON.parse(window.localStorage.listCouldntTry ?? "[]")
    let preActionLength = listCouldntTry.length

    if (laCase.classList.contains("on")) {
      laCase.classList.remove("on")

      listCouldntTry = listCouldntTry.filter(el => el !== nAni)

      window.localStorage.listCouldntTry = JSON.stringify(listCouldntTry)

      if (preActionLength > 0 && listCouldntTry.length === 0) {
        // Do it instant first to avoid weird graphical effect
        let wrapNotifConf = document.getElementById(
          "wrapper_notification_undone_confirm"
        )
        wrapNotifConf.classList.remove("show")
        wrapNotifConf.classList.add("hide")

        // Do this to tell page to do it always
        let page = document.getElementById("page")

        const event = new CustomEvent("noMoreConfirm")

        if (page) page.dispatchEvent(event)
      }

      arr = casesCochees.filter(el => el !== nAni)
    } else {
      laCase.classList.add("on")
      arr = [...casesCochees, nAni]
    }

    let caseConfirm = arr
    localStorage.caseConfirm = JSON.stringify(caseConfirm)
    setCasesCochees(arr)
  }
  const askSureToConfirm = () => {
    let valO = window.localStorage.valO === "true" ? true : false

    if (valO) {
      doAlert("Vous n'avez pas le droit de confirmer des animaux.")
      return
    }

    resultConfirmations = []

    window.location.hash = "inventaire_goconfirm"
  }
  const goConfirm = async () => {
    let divModal = document.getElementById("loader_do_confirm")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let dataToConfirm = []
    let changeDone = false

    for (let i in casesCochees) {
      let data = {}
      allData.forEach(
        ani => casesCochees[i] === ani.COPAIP + ani.NUNATI && (data = ani)
      )
      data["numFormatSpace"] = data.COPAIP + " " + data.NUNATI

      dataToConfirm.push(data)
    }

    dataToConfirm.sort((a, b) =>
      a.DANAIS > b.DANAIS ? 1 : b.DANAIS > a.DANAIS ? -1 : 0
    )

    let listeResult = []
    let listeSuccess = []

    let listCouldntTry = JSON.parse(window.localStorage.listCouldntTry ?? "[]")
    let caseConfirm = JSON.parse(localStorage.caseConfirm ?? "[]")

    let dataObject = await localforage.getItem(
      "dataCalculated" + dataSuffix + numCheptel
    )
    let animalsObj = dataObject?.inventaire?.Custom

    for (let i in dataToConfirm) {
      let ii = +i + 1
      let ed = true
      let ar = JSON.stringify(dataToConfirm[i])
      let n = dataToConfirm[i].numFormatSpace
      let nNoSpace = n.replace(" ", "")
      let d = dataToConfirm[i].DANAIS
      let e = ""

      let result = await fetchGoConfirm(ii, ed, ar, n, d, e)
      // let result = null

      if (result?.rejet !== undefined) {
        changeDone = true
        let identDemande = result.ident_demande
        let rejected = result.rejet !== 0 ? true : false
        let rejets = null

        if (rejected) {
          rejets = []

          for (ii in result.liste_rejet) {
            rejets.push(result.liste_rejet[ii].lib_rejet)
          }
        } else {
          listeSuccess.push(nNoSpace)
        }

        lastIdentConfirm = identDemande

        let formatedResult = {
          numani: nNoSpace,
          rejected: rejected,
          identDemande: identDemande,
          rejets: rejets,
        }

        listeResult.push(formatedResult)

        caseConfirm = caseConfirm.filter(ani => ani !== nNoSpace)
        listCouldntTry = listCouldntTry.filter(ani => ani !== nNoSpace)
      } else {
        listCouldntTry.push(nNoSpace)
      }
    }

    window.localStorage.listCouldntTry = JSON.stringify(listCouldntTry)

    resultConfirmations = listeResult

    localStorage.caseConfirm = JSON.stringify(caseConfirm)

    setCasesCochees(caseConfirm)

    let rfqual = getRFQUAL()

    if (listeSuccess.length > 0) {
      animalsObj.forEach(ani => {
        let nAni = ani.COPAIP + ani.NUNATI
        let nMere = ani?.numMere ?? null
        let nPere = ani?.numPere ?? null

        if (listeSuccess.includes(nAni)) {
          let sexbov = ani.SEXBOV
          let etqubo = ani.borexa_etqubo
          let coqubo = ani.borexa_coqubo
          let ccqubo = ani.borexa_ccqubo

          ani.reponse = "non"
          ani.erreurs = [[1, "L'animal est déjà inscrit", 0]]

          ani.A_boqual_ETQUBO = etqubo
          ani.A_boqual_COQUBO = coqubo
          ani.A_boqual_CCQUBO = ccqubo
          ani.A_boqual_DAQUBO = todayDate()
          ani.inscrAnimal = rfqual[sexbov][etqubo][coqubo][ccqubo].LIBECO
          ani.inscrAnimalLIDETA = rfqual[sexbov][etqubo][coqubo][ccqubo].LIDETA
          ani.inscrAnimalLIBELO = rfqual[sexbov][etqubo][coqubo][ccqubo].LIBELO

          ani.examAni = ""
          ani.examAniLIBELO = ""
          ani.examAniLIDETA = ""
          ani.borexa_c_CORADI = ""
          ani.borexa_c_COREEX = ""
          ani.borexa_c_ETQUBO = ""
          ani.borexa_ccqubo = ""
          ani.borexa_copace = ""
          ani.borexa_copoin = ""
          ani.borexa_coqubo = ""
          ani.borexa_coreex = ""
          ani.borexa_corefu = ""
          ani.borexa_daexbo = ""
          ani.borexa_etqubo = ""
          ani.borexa_nuchex = ""
        }

        if (listeSuccess.includes(nMere)) {
          let sexbov = "2"
          let etqubo = "1"
          let coqubo = ani.coqubo_exa_mere
          let ccqubo = ani.ccqubo_exa_mere

          ani.inscrMere = rfqual?.[sexbov]?.[etqubo]?.[coqubo]?.[ccqubo]?.LIBECO
          ani.inscrMereLIDETA =
            rfqual?.[sexbov]?.[etqubo]?.[coqubo]?.[ccqubo]?.LIDETA
          ani.inscrMereLIBELO =
            rfqual?.[sexbov]?.[etqubo]?.[coqubo]?.[ccqubo]?.LIBELO

          ani.coqubo_exa_mere = ""
          ani.ccqubo_exa_mere = ""
          ani.etqubo_exa_mere = ""

          ani?.erreurs?.forEach((erreur, index) => {
            if (erreur[0] === 5) ani.erreurs.splice(index, 1)
          })

          ani.reponse = ani.erreurs.length > 0 ? "non" : "ok"
        }

        if (listeSuccess.includes(nPere)) {
          let sexbov = "1"
          let etqubo = "1"
          let coqubo = ani.coqubo_exa_pere
          let ccqubo = ani.ccqubo_exa_pere

          ani.inscrPere = rfqual?.[sexbov]?.[etqubo]?.[coqubo]?.[ccqubo]?.LIBECO
          ani.inscrPereLIDETA =
            rfqual?.[sexbov]?.[etqubo]?.[coqubo]?.[ccqubo]?.LIDETA
          ani.inscrPereLIBELO =
            rfqual?.[sexbov]?.[etqubo]?.[coqubo]?.[ccqubo]?.LIBELO

          ani.coqubo_exa_pere = ""
          ani.ccqubo_exa_pere = ""
          ani.etqubo_exa_pere = ""

          ani?.erreurs?.forEach((erreur, index) => {
            if (erreur[0] === 4) ani.erreurs.splice(index, 1)
          })

          ani.reponse = ani.erreurs.length > 0 ? "non" : "ok"
        }
      })

      await localforage.setItem(
        "dataCalculated" + dataSuffix + numCheptel,
        dataObject
      )

      document.getElementById("app")?.dispatchEvent(
        new CustomEvent("setNewData", {
          detail: {
            dataCalculated: dataObject,
          },
        })
      )

      setDataCalculated(dataObject.inventaire)
    }

    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))

    if (listCouldntTry.length !== 0)
      if (changeDone)
        doAlert(
          "Certaines confirmations n'ont pas pu être réalisées. Veuillez réessayer ces confirmations plus tard s.v.p"
        )
      else
        doAlert(
          "Les confirmations n'ont pas pu être réalisées. Veuillez réessayer plus tard s.v.p"
        )

    if (changeDone) {
      setModalAskConfirm(false)
      setModalResultConfirm(true)
    } else {
      goBack()
    }
  }
  ////////////////////////////////////////////////////////
  // Methods to handle the "DECLARATION" functionnality //
  ////////////////////////////////////////////////////////
  const cocheeCaseDecl = animal => {
    let nAni = animal.COPAIP + animal.NUNATI
    let laCase = document.getElementById("case" + nAni)
    let arr = casesCocheesDecl
    let listCouldntTry = JSON.parse(
      window.localStorage.listCouldntTryDecl ?? "[]"
    )
    let preActionLength = listCouldntTry.length

    if (laCase.classList.contains("on")) {
      laCase.classList.remove("on")

      if (optionsDeclCochees[nAni]) delete optionsDeclCochees[nAni]

      listCouldntTry = listCouldntTry.filter(el => el !== nAni)

      window.localStorage.listCouldntTryDecl = JSON.stringify(listCouldntTry)

      if (preActionLength > 0 && listCouldntTry.length === 0) {
        // Do it instant first to avoid weird graphical effect
        let wrapNotifDecl = document.getElementById(
          "wrapper_notification_undone_declare"
        )
        wrapNotifDecl.classList.remove("show")
        wrapNotifDecl.classList.add("hide")

        // Do this to tell page to do it always
        let page = document.getElementById("page")

        const event = new CustomEvent("noMoreDecl")

        if (page) page.dispatchEvent(event)
      }

      arr = casesCocheesDecl.filter(el => el !== nAni)
    } else {
      laCase.classList.add("on")
      arr = [...casesCocheesDecl, nAni]
    }

    let caseDeclar = arr
    localStorage.caseDeclar = JSON.stringify(caseDeclar)
    setCasesCocheesDecl(arr)
  }
  const askSureToDeclar = () => {
    if (seriesLanaud === "[]") return
    aniToDeclare = []

    let numSerie = liveFilters[customNum].declarations.value
    let listeSeries = JSON.parse(seriesLanaud)
    libelleSerie = listeSeries[numSerie].libelo

    let dateDebut = +(listeSeries[numSerie]?.dadena?.replaceAll("-", "") ?? 0)
    let dateFin = +(listeSeries[numSerie]?.dafina?.replaceAll("-", "") ?? 0)

    casesCocheesDecl.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))

    casesCocheesDecl.forEach(nAni => {
      let data = {}
      allData.forEach(ani => nAni === ani.COPAIP + ani.NUNATI && (data = ani))
      let danais = +formatDate4(data.DANAIS)

      if (dateDebut <= danais && dateFin >= danais) {
        aniToDeclare.push({
          numAnimal: data.COPAIP + data.NUNATI,
          nom: data.NOBOVI,
          datenaissance: data.DANAIS,
        })

        if (!optionsDeclCochees[nAni])
          optionsDeclCochees[nAni] = {
            evalim: 0,
            ssCornes: 0,
            techIa: 0,
          }
      }
    })

    window.localStorage.optionsDeclCochees = JSON.stringify(optionsDeclCochees)

    window.location.hash = "inventaire_declarations"
  }
  const cocheOptionsDeclar = (e, numAnimal, option) => {
    if (e.target.className === "coche") {
      e.target.className = "coche on"

      if (option === "evalim") optionsDeclCochees[numAnimal].evalim = 1
      if (option === "techIa") optionsDeclCochees[numAnimal].techIa = 1
    } else {
      e.target.className = "coche"

      if (option === "evalim") optionsDeclCochees[numAnimal].evalim = 0
      if (option === "techIa") optionsDeclCochees[numAnimal].techIa = 0
    }

    window.localStorage.optionsDeclCochees = JSON.stringify(optionsDeclCochees)
  }
  const validateDeclarations = async () => {
    let divModal = document.getElementById("loader_do_declare")
    if (divModal) divModal.dispatchEvent(new CustomEvent("showModal"))

    let numSerie = liveFilters[customNum].declarations.value
    let listeSeries = JSON.parse(seriesLanaud)

    let dateDebut = +(listeSeries[numSerie]?.dadena?.replaceAll("-", "") ?? 0)
    let dateFin = +(listeSeries[numSerie]?.dafina?.replaceAll("-", "") ?? 0)

    let lineStringToSend = ""
    for (let numAni in optionsDeclCochees) {
      let data = {}
      allData.forEach(ani => numAni === ani.COPAIP + ani.NUNATI && (data = ani))

      let evalim = optionsDeclCochees[numAni].evalim
      let ssCornes = optionsDeclCochees[numAni].ssCornes
      let techIa = optionsDeclCochees[numAni].techIa

      if (!data.DANAIS) continue

      let danais = +formatDate4(data.DANAIS)

      if (dateDebut <= danais && dateFin >= danais)
        lineStringToSend += `${numAni};${evalim};${ssCornes};${techIa}|`
    }

    let declareToLanaud = await declareLanaud(libelleSerie, lineStringToSend)

    if (declareToLanaud && declareToLanaud.message) {
      messageDeclaration = { __html: declareToLanaud.message }

      let dataObject = await localforage.getItem(
        "dataCalculated" + dataSuffix + numCheptel
      )
      let animalsObj = dataObject?.inventaire?.Custom

      animalsObj.forEach(ani => {
        let nAni = ani.COPAIP + ani.NUNATI

        if (casesCocheesDecl.includes(nAni)) {
          ani.dejaDeclare = true

          let caseDecl = document.getElementById("case_declar_" + nAni)

          if (caseDecl) caseDecl.innerHTML = "Déjà déclaré"
        }
      })

      await localforage.setItem(
        "dataCalculated" + dataSuffix + numCheptel,
        dataObject
      )

      document.getElementById("app")?.dispatchEvent(
        new CustomEvent("setNewData", {
          detail: {
            dataCalculated: dataObject,
          },
        })
      )

      localStorage.caseDeclar = "[]"
      setCasesCocheesDecl([])

      setModalAskDeclar(false)
      setModalResultDeclar(true)
    } else {
      window.localStorage.listCouldntTryDecl = JSON.stringify(casesCocheesDecl)

      doAlert(
        "Un problème est survenu lors de la déclaration. Veuillez réessayer plus tard s.v.p"
      )

      if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))

      goBack()
    }
    if (divModal) divModal.dispatchEvent(new CustomEvent("hideModal"))
  }
  const nbDeclared = () => {
    let nb = 0

    if (casesCocheesDecl.length !== 0) {
      dataSorted.forEach(animal => {
        let nAni = animal.COPAIP + animal.NUNATI
        if (casesCocheesDecl.includes(nAni)) nb++
      })
    }

    return nb
  }
  ////////////////////////////////////////////////////////
  // Methods to handle the "COMPARAISON" functionnality //
  ////////////////////////////////////////////////////////
  const cocheeCaseCompare = animal => {
    let nAni = animal.COPAIP + animal.NUNATI
    let laCase = document.getElementById("case" + nAni)
    let arr = casesCocheesCompare

    if (laCase.classList.contains("on")) {
      laCase.classList.remove("on")
      arr = casesCocheesCompare.filter(el => el !== nAni)
    } else {
      if (casesCocheesCompare.length < NB_MAX_ANI_COMPARABLE) {
        laCase.classList.add("on")
        arr = [...casesCocheesCompare, nAni]
      } else {
        doAlert(
          `Vous avez atteint la limite du nombre d'animaux comparable en même temps.(maximum: ${NB_MAX_ANI_COMPARABLE})`
        )
      }
    }

    let caseCompare = arr
    localStorage["caseCompare" + customNum] = JSON.stringify(caseCompare)
    setCasesCocheesCompare(arr)
  }
  const goCompare = () => {
    if (casesCocheesCompare.length > 1) {
      window.location.hash = `comparaison|${JSON.stringify(
        casesCocheesCompare
      )}`
    } else {
      doAlert(
        "Veuillez sélectionner au moins deux animaux pour la comparaison s.v.p."
      )
    }
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Methods to handle moving the table by holding the mouse (kinda like touch behaviour but with a mouse) //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  const tbodyMouseDown = e => {
    if (e.button !== 0) return

    clickHeld = true
    let divInv = document.getElementById("inventaire_table")
    if (!isMobile) divInv.classList.add("cursor_grab")

    mouseX = e.nativeEvent.pageX
    mouseY = e.nativeEvent.pageY
    originMouseX = e.nativeEvent.pageX
    originMouseY = e.nativeEvent.pageY
  }
  const tbodyMouseMove = e => {
    if (clickHeld) {
      let divInv = document.getElementById("inventaire_table")
      divInv.scrollBy(
        mouseX - e.nativeEvent.pageX,
        mouseY - e.nativeEvent.pageY
      )

      if (Math.abs(originMouseX - e.nativeEvent.pageX) > 10) hasMoved = true
      if (Math.abs(originMouseY - e.nativeEvent.pageY) > 10) hasMoved = true

      mouseX = e.nativeEvent.pageX
      mouseY = e.nativeEvent.pageY
    }
  }
  const tbodyMouseUp = e => {
    if (clickHeld) {
      let divInv = document.getElementById("inventaire_table")
      if (!isMobile) divInv.classList.remove("cursor_grab")
      clickHeld = false
      setTimeout(() => {
        hasMoved = false
      }, 0)
    }
  }

  return (
    <>
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      <ModalLoader
        id="loader_certificate"
        message="Téléchargement en cours..."
      />
      <table id="table-to-xlsx" className="big_table custom_inv" ref={tableInv}>
        <thead>
          <tr>
            {action === "confirm" && (
              <th
                className={colConfirm}
                onClick={() =>
                  sortColumn(dataSorted, customNum, "colConfirm", "colConfirm")
                }
              >
                &nbsp;
              </th>
            )}
            {action === "declar" && (
              <th
                className={colDeclar}
                onClick={() =>
                  sortColumn(dataSorted, customNum, "colDeclar", "colDeclar")
                }
              >
                &nbsp;
              </th>
            )}
            {action === "compare" && (
              <th
                className={colCompare}
                onClick={() =>
                  sortColumn(dataSorted, customNum, "colCompare", "colCompare")
                }
              >
                &nbsp;
              </th>
            )}

            <th
              className={numTravail + " ripple2"}
              onClick={() =>
                sortColumn(dataSorted, customNum, "numtravail", "numtravail")
              }
            >
              N° de travail
            </th>
            {customInventory?.columns?.map(col => {
              if (!columns[col]) return

              let name = columns[col]?.name
              let sort = columns[col]?.sort

              switch (col) {
                case "retyuptrtxt":
                  name = (
                    <>
                      Translocation
                      <br />
                      <sup>1</sup>/<sub>29</sub>
                    </>
                  )
                  break

                default:
                  break
              }

              return (
                <th
                  key={columns[col]?.name}
                  className={classColums[col] + " ripple2"}
                  onClick={() => sortColumn(dataSorted, customNum, sort, col)}
                >
                  {name}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody
          onMouseDown={tbodyMouseDown}
          onMouseUp={tbodyMouseUp}
          onMouseMove={tbodyMouseMove}
          onMouseLeave={tbodyMouseUp}
        >
          {dataSorted?.map((animal, i) => (
            <tr
              key={animal.COPAIP + animal.NUNATI}
              id={animal.COPAIP + animal.NUNATI}
              data-index={i}
            >
              {action === "confirm" && (
                <td
                  id={"case_confirm_" + animal.COPAIP + animal.NUNATI}
                  className="first_child_choice_table"
                  onClick={() =>
                    animal.reponse === "ok"
                      ? cocheeCase(animal)
                      : showErreurs(animal)
                  }
                >
                  {animal?.reponse === "ok" ? (
                    <div
                      id={`case${animal.COPAIP + animal.NUNATI}`}
                      className={
                        casesCochees?.includes(animal.COPAIP + animal.NUNATI)
                          ? `cocheConfirmable on`
                          : `cocheConfirmable`
                      }
                    ></div>
                  ) : (
                    <div className="erreurNonConfirmable"></div>
                  )}
                </td>
              )}
              {action === "declar" && (
                <td
                  id={"case_declar_" + animal.COPAIP + animal.NUNATI}
                  className="first_child_choice_table"
                  onClick={() => !animal.dejaDeclare && cocheeCaseDecl(animal)}
                >
                  {animal.dejaDeclare ? (
                    <>Déjà déclaré</>
                  ) : (
                    <div
                      id={`case${animal.COPAIP + animal.NUNATI}`}
                      className={
                        casesCocheesDecl?.includes(
                          animal.COPAIP + animal.NUNATI
                        )
                          ? `cocheConfirmable on`
                          : `cocheConfirmable`
                      }
                    ></div>
                  )}
                </td>
              )}
              {action === "compare" && (
                <td
                  className="first_child_choice_table"
                  onClick={() => cocheeCaseCompare(animal)}
                >
                  <div
                    id={`case${animal.COPAIP + animal.NUNATI}`}
                    className={
                      casesCocheesCompare?.includes(
                        animal.COPAIP + animal.NUNATI
                      )
                        ? `cocheConfirmable on`
                        : `cocheConfirmable`
                    }
                  ></div>
                </td>
              )}
              <td
                className="td-center numanimal"
                data-column="numtrav"
                onClick={() =>
                  goThere(`animal|${animal.COPAIP + animal.NUNATI}`)
                }
              >
                <span>{animal.numAnimal}</span>
                <span className="numtrav">{animal.numTravailAnimal}</span>
              </td>
              {customInventory?.columns?.map(col => {
                if (!columns[col]) return

                let tdContent
                let tdClass = ""
                let tdId = ""
                let tdOnclick = undefined

                switch (col) {
                  case "numanimal":
                    tdContent = animal["COPAIP"] + animal["NUNATI"]
                    break
                  case "SEXBOV":
                    if (animal.SEXBOV === "2")
                      if (animal.COFGMU > 1)
                        tdContent = (
                          <div className="sexe femelle_jumelle">2</div>
                        )
                      else tdContent = <div className="sexe femelle">2</div>
                    if (animal.SEXBOV === "1")
                      if (animal.COFGMU > 1)
                        tdContent = <div className="sexe male_jumeau">1</div>
                      else tdContent = <div className="sexe male">1</div>
                    break
                  case "DANAIS":
                    tdContent = animal[col] ? animal[col] : ""
                    break
                  case "inscrAnimal":
                  case "inscrPere":
                  case "inscrMere":
                    tdId = col + "_" + animal.COPAIP + animal.NUNATI

                    if (animal[col]) {
                      tdClass = "nowrap icone_pdf_certif"
                      if (
                        col === "inscrAnimal" &&
                        animal?.A_boqual_COQUBO !== "04" &&
                        animal?.A_boqual_COQUBO !== "ST"
                      )
                        tdClass += " pointer"

                      tdContent = (
                        <div className="inscription">
                          {animal[col]}
                          {col === "inscrAnimal" &&
                            animal?.A_boqual_COQUBO !== "04" &&
                            animal?.A_boqual_COQUBO !== "ST" && (
                              <span className="material-symbols-outlined ripple2 material-symbols-outlined_even_more_weight">
                                picture_as_pdf
                              </span>
                            )}
                        </div>
                      )
                      if (col === "inscrAnimal")
                        tdOnclick = () => showCertificate(animal)
                    }

                    break
                  // case "pntp":
                  //   if (animal[col]) tdContent = animal.pntp
                  //   break
                  case "bopoat120":
                  case "bopoat210":
                    if (animal[col]) tdContent = animal[col] + " kg"
                    break
                  case "numMere":
                    tdClass = "underline dontalign pointer"
                    tdOnclick = () => goThere(`animal|${animal?.numMere}`)
                    tdContent = (
                      <>
                        {animal?.numMere?.substring(
                          0,
                          animal?.numMere.length - 4
                        )}
                        <span className="numtrav">
                          {animal?.numMere?.substring(
                            animal?.numMere.length - 4
                          )}
                        </span>
                      </>
                    )
                    break
                  case "numNomMere":
                    tdClass = "underline dontalign pointer"
                    tdOnclick = () => goThere(`animal|${animal?.numMere}`)
                    tdContent = (
                      <>
                        <span>
                          {animal?.numMere?.substring(
                            0,
                            animal?.numMere.length - 4
                          )}
                          <span className="numtrav">
                            {animal?.numMere?.substring(
                              animal?.numMere.length - 4
                            )}
                          </span>
                        </span>
                        <br />
                        <span>{animal.Mnobovi}</span>
                      </>
                    )
                    break
                  case "numPere":
                    tdClass = "underline dontalign pointer"
                    tdOnclick = () => goThere(`animal|${animal?.numPere}`)
                    tdContent = (
                      <>
                        {animal?.numPere?.substring(
                          0,
                          animal?.numPere.length - 4
                        )}
                        <span className="numtrav">
                          {animal?.numPere?.substring(
                            animal?.numPere.length - 4
                          )}
                        </span>
                      </>
                    )
                    break
                  case "numNomPere":
                    tdClass = "underline dontalign pointer"
                    tdOnclick = () => goThere(`animal|${animal?.numPere}`)
                    tdContent = (
                      <>
                        <span>
                          {animal?.numPere?.substring(
                            0,
                            animal?.numPere.length - 4
                          )}
                          <span>
                            {animal?.numPere?.substring(
                              animal?.numPere.length - 4
                            )}
                          </span>
                        </span>
                        <br />
                        <span className="numtrav">{animal.Pnobovi}</span>
                      </>
                    )
                    break
                  case "AGE1VE":
                    if (animal[col] !== "")
                      tdContent = `${Math.ceil(animal[col] / 30.4)} mois`
                    break
                  case "DADEVE":
                    if (animal[col] && animal[col] !== "--")
                      tdContent = <div className="nowrap">{animal[col]}</div>
                    break
                  case "IVVMOY":
                    if (animal[col] !== "")
                      tdContent = (
                        <>
                          {animal[col]} jrs
                          <sub>
                            <i> ({animal["NIVVCA"]} pris en compte)</i>
                          </sub>
                        </>
                      )
                    break
                  case "IVV1A2":
                    animal[col] &&
                      (tdContent = <div>{`${animal[col]} jrs`}</div>)
                    break
                  case "IVV2PL":
                    animal[col] &&
                      (tdContent = <div>{`${animal[col]} jrs`}</div>)
                    break
                  case "TYPANA":
                    tdContent = (
                      <>
                        {(animal.CODEUT === "1" ||
                          animal.CODEUT === "4" ||
                          animal.CODEUT === "5" ||
                          animal.CODEUT === "8") && (
                          <span className="span-center">
                            {animal.TYPANA_txt}
                            <p>utilisable</p>
                          </span>
                        )}
                        {animal.CODEUT === "2" && (
                          <span className="span-center porteur1p">
                            {animal.TYPANA_txt}
                            <p>inutilisable</p>
                          </span>
                        )}
                        {animal.CODEUT === "9" && (
                          <span className="span-center porteur1p">
                            {animal.TYPANA_txt}
                            <p>inconnu en bdnsat</p>
                          </span>
                        )}

                        <span className="span-date">{animal.DAOBAN}</span>
                      </>
                    )
                    break
                  case "CORDCG":
                    if (animal.CORDCG) {
                      tdOnclick = () =>
                        animal.filiation &&
                        goThere(
                          `inventaire_filiation|${
                            animal.COPAIP + animal.NUNATI
                          }`
                        )

                      tdClass = animal.filiation ? "pointer" : ""

                      tdContent = (
                        <>
                          <span
                            className={(() => {
                              let cl = ""

                              if (
                                animal.CORDCG !== "C" &&
                                animal.CORDCG !== "D" &&
                                animal.CORDCG !== "E" &&
                                animal.CORDCG !== "1"
                              )
                                cl += " porteur2p"

                              if (animal.filiation) cl += " filiation pointer"

                              return cl
                            })()}
                          >
                            <nobr>{animal.cordcg_txt}</nobr>
                          </span>
                          <span className="span-date">{animal.dartcg}</span>
                        </>
                      )
                    } else if (animal.COFILI === "O") {
                      tdContent = (
                        <div className="prelev_attente">En attente</div>
                      )
                    }
                    break
                  case "retyupsc":
                    tdContent = (
                      <>
                        {!animal.retyupsc && animal.COPOLL === "O" ? (
                          <div className="prelev_attente">En attente</div>
                        ) : (
                          animal.retyupsc && (
                            <>
                              <div
                                className={
                                  animal.retyupsc === "+/+" ||
                                  animal.retyupsc === ""
                                    ? "span-center"
                                    : "porteur2p"
                                }
                              >
                                {animal.retyupsc}
                              </div>
                              <span className="span-date">
                                {animal.daretysc}
                              </span>
                            </>
                          )
                        )}
                      </>
                    )
                    break
                  case "retyupmh":
                    tdContent = (
                      <>
                        {!animal.retyupmh && animal.COCULA === "O" ? (
                          <div className="prelev_attente">En attente</div>
                        ) : (
                          animal.retyupmh && (
                            <>
                              <div
                                className={
                                  animal.retyupmh === "+/+" ||
                                  animal.retyupmh === ""
                                    ? "span-center"
                                    : "porteur2p"
                                }
                              >
                                {animal.retyupmh}
                              </div>
                              <span className="span-date">
                                {animal.daretymh}
                              </span>
                            </>
                          )
                        )}
                      </>
                    )
                    break
                  case "retyuppro":
                    tdContent = (
                      <>
                        {!animal.retyuppro && animal.COPAFE === "O" ? (
                          <div className="prelev_attente">En attente</div>
                        ) : (
                          animal.retyuppro && (
                            <>
                              <div
                                className={
                                  animal.retyuppro === "+/+" ||
                                  animal.retyuppro === "" ||
                                  !animal.retyuppro
                                    ? "span-center"
                                    : "porteur2p"
                                }
                              >
                                {animal.retyuppro}
                              </div>
                              <span className="span-date">
                                {animal.daretypro}
                              </span>
                            </>
                          )
                        )}
                      </>
                    )
                    break
                  case "retyupbli":
                    tdContent = (
                      <>
                        {!animal.retyupbli && animal.COPAFE === "O" ? (
                          <div className="prelev_attente">En attente</div>
                        ) : (
                          animal.retyupbli && (
                            <>
                              <div
                                className={
                                  animal.retyupbli === "+/+" ||
                                  animal.retyupbli === "" ||
                                  !animal.retyupbli
                                    ? "span-center"
                                    : "porteur2p"
                                }
                              >
                                {animal.retyupbli}
                              </div>
                              <span className="span-date">
                                {animal.daretybli}
                              </span>
                            </>
                          )
                        )}
                      </>
                    )
                    break
                  case "retyuppaf":
                    tdContent = (
                      <>
                        {animal.retyuppaf ? (
                          <>
                            <span className="td-center">
                              {animal.retyuppaf === "+/+"
                                ? "Non porteur"
                                : animal.retyuppaf === "PAL/+"
                                ? "Porteur hétérozygote"
                                : animal.retyuppaf === "PAL/PAL"
                                ? "Porteur homozygote"
                                : animal.retyuppaf}
                            </span>
                            {animal.daretypaf && (
                              <span className="span-date">
                                le {animal.daretypaf}
                              </span>
                            )}
                          </>
                        ) : (
                          animal.COPAFE === "O" && (
                            <div className="prelev_attente">En attente</div>
                          )
                        )}
                      </>
                    )
                    break

                  case "retyuptrtxt":
                    tdContent = (
                      <>
                        <span
                          className={
                            animal.RETYUP_TR === "NR" ||
                            animal.RETYUP_TR === "" ||
                            !animal.RETYUP_TR
                              ? "span-center"
                              : "porteur2p"
                          }
                        >
                          {animal.retyuptrtxt}
                        </span>
                        <span className="span-date">{animal.daretytr}</span>
                      </>
                    )
                    break
                  case "IDLABO":
                    if (animal.IDLABO !== "") {
                      tdOnclick = e => {
                        goThere(
                          `inventaire_evalim|${animal.COPAIP + animal.NUNATI}`
                        )
                      }

                      tdClass = "pointer"

                      tdContent = (
                        <>
                          <button className="button-result pointer">
                            Voir les résultats
                          </button>
                          {animal.DATINS && (
                            <span className="span-date">
                              le {animal.DATINS}
                            </span>
                          )}
                        </>
                      )
                    }
                    break
                  case "examAni":
                    tdId = col + "_" + animal.COPAIP + animal.NUNATI

                    if (animal.borexa_coreex && !animal?.inscrAnimal) {
                      let coreexLib =
                        animal.borexa_coreex === "R"
                          ? "Refusé"
                          : animal.borexa_coreex === "C"
                          ? `Conforme (${animal?.examAni})`
                          : animal.borexa_coreex === "A"
                          ? "Ajourné"
                          : ""

                      let refusLib = (animal.borexa_coreex === "R" ||
                        animal.borexa_coreex === "A") && (
                        <div className="exam-erreur">
                          {animal.borexaCorefuLib}
                        </div>
                      )

                      tdContent = (
                        <>
                          {coreexLib}
                          {refusLib}
                        </>
                      )
                    }
                    break
                  case "examAniLIBELO":
                    if (animal.borexa_coreex && !animal?.inscrAnimal) {
                      tdContent = animal.examAniLIBELO
                    }
                    break
                  case "examAniLIDETA":
                    if (animal.borexa_coreex && !animal?.inscrAnimal) {
                      tdContent = animal.examAniLIDETA
                    }
                    break
                  case "coreex_exa_mere":
                    if (animal.coreex_exa_mere && !animal?.inscrMere) {
                      if (animal.coreex_exa_mere === "R")
                        tdContent = (
                          <>
                            Examen
                            <br />
                            Refusé
                          </>
                        )

                      if (animal.coreex_exa_mere === "C")
                        tdContent = (
                          <>
                            Examen
                            <br />
                            {`Conforme (${animal?.examMere})`}
                          </>
                        )

                      if (animal.coreex_exa_mere === "A")
                        tdContent = (
                          <>
                            Examen
                            <br />
                            Ajourné
                          </>
                        )
                    }
                    break
                  case "examMereLIBELO":
                    if (animal.coreex_exa_mere && !animal?.inscrMere) {
                      tdContent = animal.examMereLIBELO
                    }
                    break
                  case "examMereLIDETA":
                    if (animal.coreex_exa_mere && !animal?.inscrMere) {
                      tdContent = animal.examMereLIDETA
                    }
                    break
                  case "inscrExamMere":
                    tdId = col + "_" + animal.COPAIP + animal.NUNATI

                    if (animal["inscrMere"]) {
                      tdClass = "nowrap icone_pdf_certif"
                      tdContent = <div>{animal["inscrMere"]}</div>
                    } else if (animal.coreex_exa_mere && !animal?.inscrMere) {
                      if (animal.coreex_exa_mere === "R")
                        tdContent = (
                          <>
                            Examen
                            <br />
                            Refusé
                          </>
                        )

                      if (animal.coreex_exa_mere === "C")
                        tdContent = (
                          <>
                            Examen
                            <br />
                            {`Conforme (${animal?.examMere})`}
                          </>
                        )

                      if (animal.coreex_exa_mere === "A")
                        tdContent = (
                          <>
                            Examen
                            <br />
                            Ajourné
                          </>
                        )
                    }
                    break
                  case "coreex_exa_pere":
                    if (animal.coreex_exa_pere && !animal?.inscrPere) {
                      if (animal.coreex_exa_pere === "R")
                        tdContent = (
                          <>
                            Examen
                            <br />
                            Refusé
                          </>
                        )

                      if (animal.coreex_exa_pere === "C")
                        tdContent = (
                          <>
                            Examen
                            <br />
                            {`Conforme (${animal?.examPere})`}
                          </>
                        )

                      if (animal.coreex_exa_pere === "A")
                        tdContent = (
                          <>
                            Examen
                            <br />
                            Ajourné
                          </>
                        )
                    }
                    break
                  case "examPereLIBELO":
                    if (animal.coreex_exa_pere && !animal?.inscrPere) {
                      tdContent = animal.examPereLIBELO
                    }
                    break
                  case "examPereLIDETA":
                    if (animal.coreex_exa_pere && !animal?.inscrPere) {
                      tdContent = animal.examPereLIDETA
                    }
                    break
                  case "prelev":
                    animal.NUPREL &&
                      (tdContent = (
                        <>
                          <span>{animal.NUPREL}</span>
                          <span className="span-date">le {animal.DAPREL}</span>
                        </>
                      ))
                    break
                  case "labo":
                    animal.NOORGA &&
                      (tdContent = (
                        <>
                          <span>{animal.NOORGA}</span>
                          <span className="span-date">
                            {animal.DARECH !== "" && (
                              <>reçu le {animal.DARECH}</>
                            )}
                            {animal.QUAPRA !== "" && (
                              <>
                                - qualité :
                                {animal.QUAPRA === "0" ? "ok" : animal.QUAPRA}
                              </>
                            )}
                          </span>
                        </>
                      ))
                    break
                  case "COIDEN":
                    tdContent = (animal.NUECHA_CODEUT ||
                      animal.NUECHA_DAOBAN ||
                      animal.COIDEN) && (
                      <>
                        <span>
                          {animal.NUECHA_CODEUT !== ""
                            ? animal.NUECHA_CODEUT === "1" ||
                              animal.NUECHA_CODEUT === "4" ||
                              animal.NUECHA_CODEUT === "5" ||
                              animal.NUECHA_CODEUT === "8"
                              ? "utilisable"
                              : animal.NUECHA_CODEUT === "2"
                              ? "inutilisable"
                              : animal.NUECHA_CODEUT === "9" &&
                                "inconnu en bdnsat"
                            : animal.COIDEN === "O" && (
                                <div className="prelev_attente">En attente</div>
                              )}
                        </span>
                        {animal.NUECHA_CODEUT !== "" && (
                          <span className="span-date">
                            le {animal.NUECHA_DAOBAN}
                          </span>
                        )}
                      </>
                    )
                    break

                  case "nuechaPere":
                    tdContent = (
                      <>
                        {animal.nuechaPere}
                        <br />
                        {animal.P_bocog2_cordcg_txt}
                      </>
                    )
                    break
                  default:
                    tdContent = animal[col]
                    break
                }

                return (
                  <td
                    key={col}
                    className={tdClass}
                    id={tdId}
                    onClick={tdOnclick}
                  >
                    {tdContent}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
      {casesCochees.length !== 0 && action === "confirm" && (
        <div className={"footer_to_confirm "}>
          <div className="div_text_footer_confirm">
            <p>
              {casesCochees.length}{" "}
              {casesCochees.length === 1 ? "animal" : "animaux"} à confirmer
            </p>
          </div>
          <div className="div_btn_footer_confirm">
            <button className="ripple6" onClick={askSureToConfirm}>
              valider
            </button>
          </div>
        </div>
      )}
      {action === "declar" && nbDeclared() !== 0 && (
        <div className={"footer_to_confirm "}>
          <div className="div_text_footer_confirm">
            <p>
              {nbDeclared()}{" "}
              {casesCocheesDecl.length === 1 ? "animal" : "animaux"} à déclarer
            </p>
          </div>
          <div className="div_btn_footer_confirm">
            <button className="ripple6" onClick={askSureToDeclar}>
              valider
            </button>
          </div>
        </div>
      )}
      {casesCocheesCompare.length !== 0 && action === "compare" && (
        <div className={"footer_to_confirm "}>
          <div className="div_text_footer_confirm">
            <p>
              {casesCocheesCompare.length}{" "}
              {casesCocheesCompare.length === 1 ? "animal" : "animaux"} à
              comparer
            </p>
          </div>
          <div className="div_btn_footer_confirm">
            <button className="ripple6" onClick={goCompare}>
              comparer
            </button>
          </div>
        </div>
      )}
      {modalFiliation && <ModalFiliation aniData={aniData} />}
      {modalEvalim && <ModalEvalim aniData={aniData} />}
      {modalErreursConf && <ModalErreursConf dataErreursConf={dataErreurs} />}
      {modalAskConfirm && (
        <ModalAskConfirm
          casesCochees={casesCochees}
          infosAni={infosAni}
          callback={goConfirm}
        />
      )}
      <ModalLoader
        id="loader_do_confirm"
        message="Confirmation(s) en cours..."
      />
      {modalResultConfirm && (
        <ModalResultConfirm
          resultConfirmations={resultConfirmations}
          lastIdent={lastIdentConfirm}
          objDate={new Date()}
        />
      )}

      {modalAskDeclar && (
        <ModalAskDeclaration
          libelleSerie={libelleSerie}
          aniToDeclare={aniToDeclare}
          optionsDeclCochees={optionsDeclCochees}
          cocheOptionsDeclar={cocheOptionsDeclar}
          validateDeclarations={validateDeclarations}
        />
      )}
      <ModalLoader id="loader_do_declare" message="Déclaration en cours..." />
      {modalResultDeclar && (
        <ModalResultDeclar
          libelleSerie={libelleSerie}
          messageDeclaration={messageDeclaration}
        />
      )}
    </>
  )
}

export default CustomInventaire
