import { useEffect, useRef, useState } from "react"
import InventaireFooter from "../components/inventaire/InventaireFooter"
import SelectData from "../components/inventaire/SelectData"
import "./Inventaire.scss"
import Loader from "../components/Loader"
import CustomInventaire from "../components/inventaire/CustomInventaire"
import {
  defaultInventoriesDefinition,
  getColumns,
  getDefaultInventories,
} from "../functions/objectColums"
import Alert from "../components/Alert"
import { useCustomAlert } from "../hooks/useCustomAlert"
import ModalFilter from "../components/inventaire/modals/ModalFilter"
import { formatDate2, formatDate4 } from "../functions/formatDate"
import Confirm from "../components/Confirm"
import { useCustomConfirm } from "../hooks/useCustomConfrm"
import ModalStatutDl from "../components/ModalStatutDl"
import ModalListeCertifs from "../components/inventaire/modals/ModalListeCertifs"
import SideFilters from "../components/inventaire/SideFilters"
import { goBack } from "../functions/handleNavigation"

let XLSX = require("xlsx")
let isScrolling // Setup isScrolling variable
let isScrollListened = false
let letsLoad = false
let searchByUser = ""

const Inventaire = ({ data, isAdherent, pageOption, downloading }) => {
  // console.log(dataCalculated)

  /////////////////////
  // HOOKS AND STUFF //
  /////////////////////
  const [dataCalculated, setDataCalculated] = useState(data)

  let isMobile = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  )

  let seriesLanaud = window.localStorage.seriesLanaud ?? "[]"
  const columnsAvailable = getColumns()

  const div = useRef()
  const div_table = useRef()
  const [alert, hideAlert, messageAlert, doAlert] = useCustomAlert() // Handle custom alert display
  const [confirm, hideConfirm, messageConfirm, doConfirm] = useCustomConfirm()

  // Handle display statut DL modal
  const [clSatutDL, setClStatutDL] = useState(false)
  pageOption === "statutdl" && !clSatutDL && setClStatutDL(true)
  pageOption !== "statutdl" && clSatutDL && setClStatutDL(false)
  !downloading && pageOption === "statutdl" && goBack()
  const [datePicker, setDatePicker] = useState(false)
  pageOption === "pickdate" && !datePicker && setDatePicker(true)
  pageOption !== "pickdate" && datePicker && setDatePicker(false)
  const [listeCertifs, setListeCertifs] = useState(false)
  pageOption === "listeCertifs" && !listeCertifs && setListeCertifs(true)
  pageOption !== "listeCertifs" && listeCertifs && setListeCertifs(false)
  const [displayAllCertif, setDisplayAllCertif] = useState(false)
  // Handle display filters modal
  const [clFilter, setClFilter] = useState(false)
  pageOption === "filter" && !clFilter && setClFilter(true)
  pageOption !== "filter" && clFilter && setClFilter(false)
  const [sidePanel, setSidePanel] = useState(false)

  // Handle display dots menu
  const [clDots, setClDots] = useState("small")
  pageOption === "dots" && clDots !== "" && setClDots("")
  pageOption !== "dots" && clDots !== "small" && setClDots("small")
  // Handle display search page
  const [searchOn, setSearchOn] = useState(false)
  pageOption === "search" && !searchOn && setSearchOn(true)
  pageOption !== "search" && searchOn && setSearchOn(false)

  let baseSortType = window.localStorage.sortType
  if (!baseSortType || baseSortType === undefined || baseSortType === "") {
    baseSortType = "numtrav"
    window.localStorage.sortType = "numtrav"
  }
  let baseSortSens = window.localStorage.sortSens
  if (!baseSortSens || baseSortSens === undefined || baseSortSens === "") {
    baseSortSens = "up"
    window.localStorage.sortSens = "up"
  }
  let baseDataType = window.localStorage.choixInv
  let baseCustInv = {}
  let baseInventories = defaultInventoriesDefinition()

  const goSelectedInv = () => {
    if (!baseDataType || baseDataType === undefined || baseDataType === "") {
      let defaultsInv = getDefaultInventories()
      let orderedList = JSON.parse(localStorage.orderedList ?? "[]")
      for (let id in defaultsInv) {
        if (!orderedList.includes(id)) orderedList.push(id)
      }

      if (orderedList[0]) {
        if (orderedList[0].includes("custom")) {
          baseDataType = orderedList[0]
          window.localStorage.choixInv = orderedList[0]
        } else {
          baseDataType = defaultsInv[+orderedList[0]].dataInv
          window.localStorage.choixInv = defaultsInv[+orderedList[0]].dataInv
        }
      } else window.localStorage.choixInv = "genealogie"
    }

    if (baseDataType.includes("custom")) {
      const listCustInv = JSON.parse(localStorage.listCustInv ?? "{}")

      let numInv = +baseDataType.replace("custom", "")
      if (!listCustInv[numInv]) {
        window.localStorage.choixInv = ""
        baseDataType = ""
        goSelectedInv()
      } else {
        let inv = listCustInv[numInv]
        inv.num = numInv
        baseCustInv = inv
      }
    } else {
      baseCustInv = structuredClone(baseInventories[baseDataType])
    }
  }

  goSelectedInv()

  // SearchBar state value
  const [searchBar, setSearchBar] = useState(
    window.localStorage.searchInv ?? ""
  )

  const [dataType, setDataType] = useState(baseDataType)
  const [customInventory, setCustomInventory] = useState(baseCustInv)
  const [filters, setFilters] = useState(
    JSON.parse(localStorage.liveFilters ?? "{}")
  )

  // Figure active sort
  let tmpClassColumns = {}
  customInventory?.columns?.forEach(col => (tmpClassColumns[col] = ""))
  let clNumTrav = ""
  let clConfirm = ""
  let clDeclar = ""
  let clCompare = ""

  let activeSort = localStorage.getItem("customsortvisual" + baseCustInv.num)
  let activeSens = localStorage.getItem("customsens" + baseCustInv.num)
  if (activeSort) {
    switch (activeSort) {
      case "numtravail":
        if (activeSens === "up") clNumTrav = "sortDown"
        else clNumTrav = "sortUp"
        break
      case "colConfirm":
        if (activeSens === "up") clConfirm = "sortDown"
        else clConfirm = "sortUp"
        break
      case "colDeclar":
        if (activeSens === "up") clDeclar = "sortDown"
        else clDeclar = "sortUp"
        break
      case "colCompare":
        if (activeSens === "up") clCompare = "sortDown"
        else clCompare = "sortUp"
        break

      default:
        if (activeSens === "up") tmpClassColumns[activeSort] = "sortDown"
        else tmpClassColumns[activeSort] = "sortUp"

        break
    }
  } else {
    if (customInventory?.action === "confirm") clConfirm = "sortDown"
    else if (customInventory?.action === "declar") clDeclar = "sortDown"
    else if (customInventory?.action === "compare") clCompare = "sortDown"
    else clNumTrav = "sortDown"
  }

  const [numTravail, setNumTravail] = useState(clNumTrav) // numTravail state variable for sort
  const [classColums, setClassColumns] = useState(tmpClassColumns) // clColumns state variable for sort
  const [colConfirm, setColConfirm] = useState(clConfirm)
  const [colDeclar, setColDeclar] = useState(clDeclar)
  const [colCompare, setColCompare] = useState(clCompare)

  // State for data + key to force re-render (otherwise doesnt work)
  const [customKey, setCustomKey] = useState(0)
  const [dataCustom, setDataCustom] = useState(
    sortColumn({
      data: filterData(searchBar, customInventory),
      customNum: customInventory.num,
      column: localStorage.getItem("customsort" + customInventory.num) ?? "",
      visualColumn:
        localStorage.getItem("customsortvisual" + customInventory.num) ?? "",
      changeSens: false,
      setVisual: false,
    })
  )

  let listeAnneesCertif = new Set()
  let listeAniByYearCertif = {}

  dataCalculated.Custom.forEach(ani => {
    if (
      ani.isInInventory &&
      ani.inscrAnimal &&
      ani?.A_boqual_COQUBO !== "04" &&
      ani?.A_boqual_COQUBO !== "ST"
    ) {
      let annee = +ani?.A_boqual_DAQUBO?.substring(6, 10)
      let numAni = ani.COPAIP + ani.NUNATI

      listeAnneesCertif.add(annee)

      if (!listeAniByYearCertif[annee]) listeAniByYearCertif[annee] = {}

      listeAniByYearCertif[annee][numAni] = {
        nom: ani.NOBOVI,
        sexe: ani.SEXBOV,
        certif: ani.inscrAnimal,
      }
    }
  })

  listeAnneesCertif = [...listeAnneesCertif]
  listeAnneesCertif.sort((b, a) => (a > b ? 1 : a < b ? -1 : 0))

  let nYear = new Date().getFullYear()

  if (!listeAnneesCertif.includes(nYear)) listeAnneesCertif.splice(0, 0, nYear)

  ////////////////
  // useEffects //
  ////////////////
  useEffect(() => {
    const searchUpdated = e => {
      setSearchBar(e.detail.searchInv)
      searchByUser = e.detail.searchInv
    }
    const sideFilters = () => setSidePanel(prev => !prev)
    const onResize = () => {
      let hash = window.location.hash
      let hVals = hash.split("|")
      let pageName = hVals[0].substring(1)
      let pageSplit = pageName.split("_")
      let actualOption = pageSplit[1]

      if (window.innerWidth >= 1100 && actualOption === "filter") {
        setSidePanel(true)
        goBack()
      } else if (
        window.innerWidth < 1100 &&
        window.localStorage.sidePanelInv === "true"
      ) {
        setSidePanel(false)
        window.location.hash = `inventaire_filter|${window.localStorage.choixInv}`
      }
    }

    let divInv = div.current

    // Event to update the search value
    divInv.addEventListener("searchUpdated", searchUpdated)
    divInv.addEventListener("sideFilters", sideFilters)
    window.addEventListener("resize", onResize)

    return () => {
      divInv.removeEventListener("searchUpdated", searchUpdated)
      divInv.removeEventListener("sideFilters", sideFilters)
      window.removeEventListener("resize", onResize)

      searchByUser = ""

      // remove notif class for side filters
      let notifUpd = document.getElementById("div_topnotif_sw")
      if (notifUpd) notifUpd.classList.remove("notif_with_side_filters")

      let notifData = document.getElementById("wrapper_notification_new_data")
      if (notifData) notifData.classList.remove("notif_with_side_filters")

      let notifConf = document.getElementById(
        "wrapper_notification_undone_confirm"
      )
      if (notifConf) notifConf.classList.remove("notif_with_side_filters")

      let notifDecl = document.getElementById(
        "wrapper_notification_undone_declare"
      )
      if (notifDecl) notifDecl.classList.remove("notif_with_side_filters")
    }
  }, [])
  useEffect(() => {
    setDataCustom(
      sortColumn({
        data: filterData(searchBar, customInventory),
        customNum: customInventory.num,
        column: localStorage.getItem("customsort" + customInventory.num) ?? "",
        visualColumn:
          localStorage.getItem("customsortvisual" + customInventory.num) ?? "",
        changeSens: false,
        setVisual: false,
      })
    )
  }, [dataCalculated])
  useEffect(() => {
    let div_inv = document.getElementById("inventaire_table")

    if (div_inv) {
      div_inv.scrollLeft =
        window.localStorage.getItem("scrollX" + dataType) ?? 0
      div_inv.scrollTop = window.localStorage.getItem("scrollY" + dataType) ?? 0

      if (
        "ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android"
      ) {
        let topActual = isAdherent && listeAnneesCertif.length !== 0 ? 180 : 121

        if (customInventory.action === "declar") topActual = 205

        let addHeight = window.localStorage.statutBarHeight
          ? +window.localStorage.statutBarHeight
          : 0
        let newHeight = topActual + addHeight

        div_inv.style.top = newHeight + "px"
      }
    }

    /* Feature detection */
    let passiveIfSupported = false

    // Check if passive eventListener is available in browser
    // Passive events are events that cant preventDefault
    // It gives way better performance on things like scroll,etc..
    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get() {
            passiveIfSupported = { passive: true }
          },
        })
      )
    } catch (err) {}

    if (isScrollListened && div_inv)
      div_inv.removeEventListener("scroll", checkScroll, passiveIfSupported)

    // Listen for scroll events
    if (div_inv)
      div_inv.addEventListener("scroll", checkScroll, passiveIfSupported)

    isScrollListened = true

    document.getElementById("selectdata")?.dispatchEvent(
      new CustomEvent("changeTab", {
        detail: {
          dataType: dataType,
        },
      })
    )

    if (customInventory.action === "declar" && seriesLanaud === "[]")
      setTimeout(() => doAlert("Aucune série de déclaration en cours."), 300)
    if (
      customInventory.action === "declar" &&
      (window.localStorage.dataSuffix ?? "") !== ""
    )
      setTimeout(
        () =>
          doAlert(
            "Pour déclarer des animaux à la station de Lanaud, vous devez revenir à votre inventaire actuel."
          ),
        300
      )
  }, [dataType])
  useEffect(() => {
    let nData = filterData(searchBar, customInventory)

    if (searchBar === "") setCustomKey("nofilter")
    else setCustomKey(searchBar)

    setDataCustom(nData)

    let div_inv = document.getElementById("inventaire_table")

    if (div_inv) {
      if (
        "ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android"
      ) {
        let topActual = isAdherent && listeAnneesCertif.length !== 0 ? 180 : 121

        if (customInventory.action === "declar") topActual = 205

        let addHeight = window.localStorage.statutBarHeight
          ? +window.localStorage.statutBarHeight
          : 0
        let newHeight = topActual + addHeight

        div_inv.style.top = newHeight + "px"
      }
    }
  }, [searchBar])
  useEffect(() => {
    let div_inv = document.getElementById("inventaire_table")

    if (div_inv) {
      if (
        "ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android"
      ) {
        let topActual = isAdherent && listeAnneesCertif.length !== 0 ? 180 : 121

        if (customInventory.action === "declar") topActual = 205

        let addHeight = window.localStorage.statutBarHeight
          ? +window.localStorage.statutBarHeight
          : 0
        let newHeight = topActual + addHeight

        div_inv.style.top = newHeight + "px"
      }
    }
  }, [searchOn])
  useEffect(() => {
    let div = document.getElementById("modal_dots_inv")

    if (div) {
      if (
        "ReactNativeWebView" in window &&
        window.localStorage.platformNative === "android"
      ) {
        let addHeight = window.localStorage.statutBarHeight
          ? +window.localStorage.statutBarHeight
          : 0

        div.style.top = addHeight + "px"
      }
    }
  }, [clDots])
  useEffect(() => {
    localStorage.liveFilters = JSON.stringify(filters)
    setDataCustom(
      sortColumn({
        data: filterData(searchBar, customInventory),
        customNum: customInventory.num,
        column: localStorage.getItem("customsort" + customInventory.num) ?? "",
        visualColumn:
          localStorage.getItem("customsortvisual" + customInventory.num) ?? "",
        changeSens: false,
        setVisual: false,
      })
    )
    setCustomKey(prev => prev + 1)
  }, [filters])
  useEffect(() => {
    if (datePicker) document.getElementById("dateInventaire").click()
    else {
      let invFooter = document.getElementById("inventaire_footer")
      const event = new Event("closePicker")
      if (invFooter) invFooter.dispatchEvent(event)
    }
  }, [datePicker])
  useEffect(() => {
    let listeAni = []
    dataCalculated?.Custom?.forEach(ani =>
      listeAni.push(ani.COPAIP + ani.NUNATI)
    )

    let listeAniAutre = []
    if (dataCalculated.aniNotInInv)
      listeAniAutre = Object.keys(dataCalculated.aniNotInInv)

    const goToAnimalFromSearch = () => {
      if (
        dataCustom.length === 0 &&
        searchBar.length === 12 &&
        searchBar.match("^[A-Z]{2}[0-9]{10}$")
      ) {
        if (listeAni.includes(searchBar) || listeAniAutre.includes(searchBar))
          window.location.hash = `animal|${searchBar}`
        else {
          document.getElementById("input_search_inventaire")?.blur()
          doConfirm(
            "Cet animal n'est pas à votre inventaire. Voulez-vous télécharger sa fiche ?"
          )
        }
      }
    }

    const searchValidated = e => {
      if (dataCustom.length === 1)
        window.location.hash = `animal|${
          dataCustom[0].COPAIP + dataCustom[0].NUNATI
        }`

      goToAnimalFromSearch()
    }

    if (searchByUser === searchBar) goToAnimalFromSearch()

    let divInv = div.current

    // Event to indicate that search was validated (enter was pressed)
    // If only one animal left in inventory, then go to fiche animal
    divInv.addEventListener("searchValidated", searchValidated)

    return () => {
      divInv.removeEventListener("searchValidated", searchValidated)
    }
  }, [dataCustom])
  useEffect(() => {
    window.localStorage.sidePanelInv = sidePanel

    // Make the table footer get the correct bottom if there is a notification + side filters
    let tableInv = document.getElementById("table-to-xlsx")
    if (tableInv) tableInv.dispatchEvent(new CustomEvent("footerPositioning"))

    let isNotifUpd = document
      .getElementById("div_topnotif_sw")
      .classList.contains("show_notif")

    let notifUpd = document.getElementById("div_topnotif_sw")

    if (isNotifUpd) {
      if (sidePanel) notifUpd.classList.add("notif_with_side_filters")
      else notifUpd.classList.remove("notif_with_side_filters")
    }

    let isNotifData = document
      .getElementById("wrapper_notification_new_data")
      .classList.contains("show")

    if (isNotifData) {
      let notifData = document.getElementById("wrapper_notification_new_data")

      if (sidePanel) notifData.classList.add("notif_with_side_filters")
      else notifData.classList.remove("notif_with_side_filters")
    }

    let isNotifConf = document
      .getElementById("wrapper_notification_undone_confirm")
      .classList.contains("show")

    if (isNotifConf) {
      let notifConf = document.getElementById(
        "wrapper_notification_undone_confirm"
      )

      if (sidePanel) notifConf.classList.add("notif_with_side_filters")
      else notifConf.classList.remove("notif_with_side_filters")
    }

    let isNotifDecl = document
      .getElementById("wrapper_notification_undone_declare")
      .classList.contains("show")

    if (isNotifDecl) {
      let notifDecl = document.getElementById(
        "wrapper_notification_undone_declare"
      )

      if (sidePanel) notifDecl.classList.add("notif_with_side_filters")
      else notifDecl.classList.remove("notif_with_side_filters")
    }
  }, [sidePanel])

  ///////////////
  // Functions //
  ///////////////
  // Get custom inventory with filter applied
  function getFilteredInventory(customInventory) {
    let inventory = dataCalculated.Custom

    if (customInventory.num === "prelevements" && !filters?.prelevements) {
      filters.prelevements = {
        prelevements: {
          value: "inventory",
        },
      }
    }

    // Prelevements brings animal from outside the inventory so speical filter for it
    // By default, dont show thoses animals unless the filter specify to do it
    if (
      !customInventory?.filters?.prelevements &&
      !filters?.[customInventory.num]?.prelevements
    ) {
      inventory = inventory.filter(ani => ani["isInInventory"])
    } else {
      let filterPrelev =
        customInventory?.filters.prelevements ??
        filters?.[customInventory.num]?.prelevements

      if (filterPrelev?.value === "inventory")
        inventory = inventory.filter(
          ani => ani["isPrelev"] && ani["isInInventory"]
        )
      else if (filterPrelev?.value === "all")
        inventory = inventory.filter(ani => ani["isPrelev"])
    }

    if (
      !customInventory &&
      !customInventory.filters &&
      Object.keys(filters[customInventory.num]).length === 0
    )
      return inventory

    let listeSeries = JSON.parse(seriesLanaud)

    // USE filters and not customInventory.filters !!
    // This is defined in livefitlers, not in inventory filters !!
    if (customInventory.action === "declar") {
      if (!filters[customInventory.num]) filters[customInventory.num] = {}
      if (!Object.keys(filters[customInventory.num]).includes("declarations")) {
        filters[customInventory.num].declarations = {
          value: Object.keys(listeSeries)[0],
        }
        localStorage.liveFilters = JSON.stringify(filters)
      }
    } else if (
      filters?.[customInventory?.num] &&
      Object.keys(filters?.[customInventory?.num]).includes("declarations")
    ) {
      delete filters[customInventory.num].declarations

      localStorage.liveFilters = JSON.stringify(filters)
    }

    if (customInventory.action === "confirm") {
      let isConfirmFilter = false

      if (customInventory.filters?.["confirmation"]) isConfirmFilter = true
      if (filters[customInventory.num]?.["confirmation"]) isConfirmFilter = true

      if (!isConfirmFilter) {
        if (customInventory.filters?.["SEXBOV"]?.origin === "autoForFilter")
          delete customInventory.filters["SEXBOV"]
      }
    }

    // A NE PAS GARDER, FAIT POUR TEST MAIS
    // ON VEUT TOUS LES ANIMAUX DANS CONFIRM !!
    // if (customInventory.action === "confirm") {
    //   inventory = inventory.filter(ani => !ani["inConfirmationObject"])
    // }

    // let arr = inventory.map(i => i.COPAIP + i.NUNATI)

    // console.log(arr)

    const applyFilter = objFilters => {
      for (let col in objFilters) {
        let filter = objFilters[col]

        switch (col) {
          case "SEXBOV":
            inventory = inventory.filter(ani => ani[col] === filter.value)
            break
          case "analyses":
            let analyseFilter = dataCalculated.AnalyseFilter
            inventory = inventory.filter(ani => {
              let nAni = ani.COPAIP + ani.NUNATI

              if (filter.value === "needAnalyse")
                return analyseFilter.needAnalyseArray.includes(nAni)
              else if (filter.value === "hasIncompatibility")
                return analyseFilter.hasIncompatibilityArray.includes(nAni)
              else if (filter.value === "noCompatibilityPere")
                return analyseFilter.maleSansCompatPereArray.includes(nAni)
              else if (filter.value === "noAnalyseMh")
                return analyseFilter.maleSansMhArray.includes(nAni)
              else if (filter.value === "noProfilAdn")
                return analyseFilter.maleSansProfilAdnArray.includes(nAni)
              else return true
            })
            break
          case "confirmation":
            let confirmFilter = dataCalculated.ConfirmFilter
            inventory = inventory.filter(ani => {
              let nAni = ani.COPAIP + ani.NUNATI

              if (filter.value === "nbGenissesToExamine") {
                customInventory.filters.SEXBOV = {
                  value: "2",
                  origin: "autoForFilter",
                }
                return confirmFilter.nbGenissesToExamineArray.includes(nAni)
              } else if (filter.value === "nbGenissesToConfirm") {
                customInventory.filters.SEXBOV = {
                  value: "2",
                  origin: "autoForFilter",
                }
                return confirmFilter.nbGenissesToConfirmArray.includes(nAni)
              } else if (filter.value === "nbVachesToConfirm") {
                customInventory.filters.SEXBOV = {
                  value: "2",
                  origin: "autoForFilter",
                }
                return confirmFilter.nbVachesToConfirmArray.includes(nAni)
              } else if (filter.value === "nbFemP6MNnInscrite") {
                customInventory.filters.SEXBOV = {
                  value: "2",
                  origin: "autoForFilter",
                }
                return confirmFilter.nbFemP6MNnInscriteArray.includes(nAni)
              } else if (filter.value === "nbMaleToExamine") {
                customInventory.filters.SEXBOV = {
                  value: "1",
                  origin: "autoForFilter",
                }
                return confirmFilter.nbMaleToExamineArray.includes(nAni)
              } else if (filter.value === "nbMaleToConfirm") {
                customInventory.filters.SEXBOV = {
                  value: "1",
                  origin: "autoForFilter",
                }
                return confirmFilter.nbMaleToConfirmArray.includes(nAni)
              } else if (filter.value === "nbMalP6MNnInscrit") {
                customInventory.filters.SEXBOV = {
                  value: "1",
                  origin: "autoForFilter",
                }
                return confirmFilter.nbMalP6MNnInscritArray.includes(nAni)
              } else return true
            })
            break
          case "prelevements":
            break
          case "declarations":
            if (seriesLanaud !== "[]") {
              let numSerie = filter?.value

              let dateDebut = +(
                listeSeries[numSerie]?.dadena?.replaceAll("-", "") ?? 0
              )
              let dateFin = +(
                listeSeries[numSerie]?.dafina?.replaceAll("-", "") ?? 0
              )

              inventory = inventory.filter(ani => {
                if (!ani.DANAIS) return false

                let danais = +formatDate4(ani.DANAIS)

                if (
                  ani.canBeDeclared &&
                  danais >= dateDebut &&
                  danais <= dateFin &&
                  ani.SEXBOV === "1"
                )
                  return true
                else return false
              })
            } else {
              inventory = []
            }
            break
          case "CORABO":
          case "COV1VE":
          case "qualifAnimal":
          case "qualifAnimalLIBELO":
          case "qualifAnimalLIDETA":
          case "catasc":
          case "borexa_coreex":
          case "examAni":
          case "examAniLIBELO":
          case "examAniLIDETA":
          case "inscrAnimal":
          case "inscrAnimalLIBELO":
          case "inscrAnimalLIDETA":
          case "inscrPere":
          case "inscrPereLIBELO":
          case "inscrPereLIDETA":
          case "qualifPere":
          case "qualifPereLIBELO":
          case "qualifPereLIDETA":
          case "inscrMere":
          case "inscrExamMere":
          case "inscrMereLIBELO":
          case "inscrMereLIDETA":
          case "qualifMere":
          case "qualifMereLIBELO":
          case "qualifMereLIDETA":
          case "ADN_filter":
          case "filiation_filter":
          case "retyupsc":
          case "retyupmh":
          case "retyuppro":
          case "retyupbli":
          case "retyuppaf":
          case "paf_filter":
          case "retyuptrtxt":
          case "Evalim_filter":
          case "NOORGA":
          case "ident_adn_filter":
          case "retyupPere":
          case "coreex_exa_pere":
          case "exam_pere_txt":
          case "examPereLIBELO":
          case "examPereLIDETA":
          case "coreex_exa_mere":
          case "exam_mere_txt":
          case "examMereLIBELO":
          case "examMereLIDETA":
          case "inscrGPP":
          case "inscrGPPLIBELO":
          case "inscrGPPLIDETA":
          case "qualifGPP":
          case "qualifGPPLIBELO":
          case "qualifGPPLIDETA":
          case "inscrPGPP":
          case "inscrPGPPLIBELO":
          case "inscrPGPPLIDETA":
          case "qualifPGPP":
          case "qualifPGPPLIBELO":
          case "qualifPGPPLIDETA":
          case "inscrMGPP":
          case "inscrMGPPLIBELO":
          case "inscrMGPPLIDETA":
          case "qualifMGPP":
          case "qualifMGPPLIBELO":
          case "qualifMGPPLIDETA":
          case "inscrGMP":
          case "inscrGMPLIBELO":
          case "inscrGMPLIDETA":
          case "qualifGMP":
          case "qualifGMPLIBELO":
          case "qualifGMPLIDETA":
          case "inscrPGMP":
          case "inscrPGMPLIBELO":
          case "inscrPGMPLIDETA":
          case "qualifPGMP":
          case "qualifPGMPLIBELO":
          case "qualifPGMPLIDETA":
          case "inscrMGMP":
          case "inscrMGMPLIBELO":
          case "inscrMGMPLIDETA":
          case "qualifMGMP":
          case "qualifMGMPLIBELO":
          case "qualifMGMPLIDETA":
          case "inscrGPM":
          case "inscrGPMLIBELO":
          case "inscrGPMLIDETA":
          case "qualifGPM":
          case "qualifGPMLIBELO":
          case "qualifGPMLIDETA":
          case "inscrPGPM":
          case "inscrPGPMLIBELO":
          case "inscrPGPMLIDETA":
          case "qualifPGPM":
          case "qualifPGPMLIBELO":
          case "qualifPGPMLIDETA":
          case "inscrMGPM":
          case "inscrMGPMLIBELO":
          case "inscrMGPMLIDETA":
          case "qualifMGPM":
          case "qualifMGPMLIBELO":
          case "qualifMGPMLIDETA":
          case "inscrGMM":
          case "inscrGMMLIBELO":
          case "inscrGMMLIDETA":
          case "qualifGMM":
          case "qualifGMMLIBELO":
          case "qualifGMMLIDETA":
          case "inscrPGMM":
          case "inscrPGMMLIBELO":
          case "inscrPGMMLIDETA":
          case "qualifPGMM":
          case "qualifPGMMLIBELO":
          case "qualifPGMMLIDETA":
          case "inscrMGMM":
          case "inscrMGMMLIBELO":
          case "inscrMGMMLIDETA":
          case "qualifMGMM":
          case "qualifMGMMLIBELO":
          case "qualifMGMMLIDETA":
          case "CIFNAI":
          case "CCRSEV":
          case "CDMSEV":
          case "CDSSEV":
          case "CFOSSE":
          case "CAVELS":
          case "CALAIT":
          case "COPSEV":
            let vals = filter?.value

            inventory = inventory.filter(ani => vals.includes(ani[col] ?? ""))
            break
          case "numNomPere":
            inventory = inventory.filter(ani => {
              let nom = ani["NOBOVI_P"]
              let num = ani["numPere"]
              return (
                nom?.toUpperCase()?.includes(filter?.value?.toUpperCase()) ||
                num?.toUpperCase()?.includes(filter?.value?.toUpperCase())
              )
            })
            break
          case "numNomMere":
            inventory = inventory.filter(ani => {
              let nom = ani["NOBOVI_M"]
              let num = ani["numMere"]
              return (
                nom?.toUpperCase()?.includes(filter?.value?.toUpperCase()) ||
                num?.toUpperCase()?.includes(filter?.value?.toUpperCase())
              )
            })
            break
          case "AGE1VE":
            inventory = inventory.filter(ani => {
              let val = Math.ceil(ani[col] / 30.4)

              if (filter.min && filter.max)
                return val >= +filter.min && val <= +filter.max
              else if (filter.min && !filter.max) return val >= +filter.min
              else if (!filter.min && filter.max) return val <= +filter.max
            })
            break
          case "pntp":
            inventory = inventory.filter(ani => {
              let val = (ani[col] ?? "").split(" ")[0]

              if (filter.min && filter.max)
                return val >= +filter.min && val <= +filter.max
              else if (filter.min && !filter.max) return val >= +filter.min
              else if (!filter.min && filter.max) return val <= +filter.max
            })
            break
          case "prelev":
            inventory = inventory.filter(ani =>
              ani["NUPREL"]
                ?.toUpperCase()
                ?.includes(filter?.value?.toUpperCase())
            )
            break
          default:
            let type = columnsAvailable[col].type
            if (type === "number") {
              inventory = inventory.filter(ani => {
                if (filter.min && filter.max)
                  return +ani[col] >= +filter.min && +ani[col] <= +filter.max
                else if (filter.min && !filter.max)
                  return +ani[col] >= +filter.min
                else if (!filter.min && filter.max)
                  return +ani[col] <= +filter.max
              })
            } else if (type === "date") {
              inventory = inventory.filter(ani => {
                let val = +formatDate4(ani[col])
                let min = +filter.min.replaceAll("-", "")
                let max = +filter.max.replaceAll("-", "")

                if (filter.min && filter.max) return val >= min && +val <= max
                else if (filter.min && !filter.max) return val >= min
                else if (!filter.min && filter.max) return val <= max
              })
            } else {
              inventory = inventory.filter(ani =>
                ani[col]?.toUpperCase()?.includes(filter?.value?.toUpperCase())
              )
            }
            break
        }
      }
    }

    applyFilter(customInventory.filters)
    applyFilter(filters[customInventory.num])

    const event = new CustomEvent("filterNumber", {
      detail: {
        nbFilters:
          Object.keys(customInventory.filters).length +
          Object.keys(filters[customInventory.num] ?? {}).length,
      },
    })

    let header = document.getElementById("header")
    if (header && div.current) setTimeout(() => header.dispatchEvent(event), 0)

    return inventory
  }
  // Logic for the dataType change
  const changeDataType = value => {
    window.localStorage.choixInv = value // Cache the index of inventory we set
    setDataType(value) // Index of inventory we set change in state

    // Find wich inventory definition to use
    let inv
    if (value.includes("custom")) {
      const listCustInv = JSON.parse(localStorage.listCustInv ?? "{}")

      let numInv = +value.replace("custom", "")
      inv = listCustInv[numInv]
      inv.num = numInv
    } else {
      inv = structuredClone(baseInventories[value])
    }

    setCustomInventory(inv)
    // When changing the definition of the inventory loaded then reload inventorydata
    let dataFiltrered = filterData(searchBar, inv)

    setDataCustom(
      sortColumn({
        data: dataFiltrered,
        customNum: inv.num,
        column: localStorage.getItem("customsort" + inv.num) ?? "",
        visualColumn: localStorage.getItem("customsortvisual" + inv.num) ?? "",
        changeSens: false,
      })
    )
    setCustomKey(prev => prev + 1)
  }
  // Method to sortColumns
  function sortColumn({
    data,
    customNum,
    column,
    visualColumn = column,
    changeSens = true,
    setVisual = true,
  }) {
    let sens = localStorage.getItem("customsens" + customNum) ?? ""

    if (column !== localStorage.getItem("customsort" + customNum)) {
      localStorage.setItem("customsort" + customNum, column)
      localStorage.setItem("customsortvisual" + customNum, visualColumn)
      localStorage.setItem("customsens" + customNum, "")
      sens = ""
    }

    if (changeSens) {
      if (sens === "up") {
        localStorage.setItem("customsens" + customNum, "down")
        sens = "down"
      } else {
        localStorage.setItem("customsens" + customNum, "up")
        sens = "up"
      }
    }
    let datatmp = structuredClone(data)
    let clColTmp = structuredClone(classColums)

    if (column === "numtravail") {
      if (sens === "up") {
        datatmp.sort((a, b) =>
          +a.numTravailAnimal > +b.numTravailAnimal
            ? 1
            : +b.numTravailAnimal > +a.numTravailAnimal
            ? -1
            : 0
        )
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          +a.numTravailAnimal > +b.numTravailAnimal
            ? 1
            : +b.numTravailAnimal > +a.numTravailAnimal
            ? -1
            : 0
        )
      }
    } else if (column === "numanimal") {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) =>
          frCollator.compare(a.COPAIP + a.NUNATI, b.COPAIP + b.NUNATI)
        )
      }
      if (sens === "down") {
        datatmp.sort((b, a) =>
          frCollator.compare(a.COPAIP + a.NUNATI, b.COPAIP + b.NUNATI)
        )
      }
    } else if (column === "colConfirm") {
      if (sens === "up") {
        datatmp.sort((b, a) => {
          let caseConfirm = JSON.parse(localStorage.caseConfirm ?? "[]")

          let valA = a.reponse
          let valB = b.reponse

          let cocheeA = caseConfirm.includes(a.COPAIP + a.NUNATI)
          let cocheeB = caseConfirm.includes(b.COPAIP + b.NUNATI)

          if (cocheeA && !cocheeB) return 1
          if (!cocheeA && cocheeB) return -1

          if (valA === "ok" && valB !== "ok") return 1
          if (valA !== "ok" && valB === "ok") return -1
          if (valA === "ok" && valB === "ok") return 0

          return 0
        })
      }
      if (sens === "down") {
        datatmp.sort((a, b) => {
          let caseConfirm = JSON.parse(localStorage.caseConfirm ?? "[]")

          let valA = a.reponse
          let valB = b.reponse

          let cocheeA = caseConfirm.includes(a.COPAIP + a.NUNATI)
          let cocheeB = caseConfirm.includes(b.COPAIP + b.NUNATI)

          if (cocheeA && !cocheeB) return 1
          if (!cocheeA && cocheeB) return -1

          if (valA === "ok" && valB !== "ok") return 1
          if (valA !== "ok" && valB === "ok") return -1
          if (valA !== "ok" && valB !== "ok") return 0

          return 0
        })
      }
    } else if (column === "colDeclar") {
      if (sens === "up") {
        datatmp.sort((b, a) => {
          let caseDeclar = JSON.parse(localStorage.caseDeclar ?? "[]")

          let valA = a.dejaDeclare
          let valB = b.dejaDeclare

          if (valA && !valB) return 1
          if (!valA && valB) return -1
          if (valA && valB) return 0

          let cocheeA = caseDeclar.includes(a.COPAIP + a.NUNATI)
          let cocheeB = caseDeclar.includes(b.COPAIP + b.NUNATI)

          if (cocheeA && !cocheeB) return 1
          if (!cocheeA && cocheeB) return -1

          return 0
        })
      }
      if (sens === "down") {
        datatmp.sort((a, b) => {
          let caseDeclar = JSON.parse(localStorage.caseDeclar ?? "[]")

          let valA = a.dejaDeclare
          let valB = b.dejaDeclare

          if (valA && !valB) return 1
          if (!valA && valB) return -1
          if (valA && valB) return 0

          let cocheeA = caseDeclar.includes(a.COPAIP + a.NUNATI)
          let cocheeB = caseDeclar.includes(b.COPAIP + b.NUNATI)

          if (cocheeA && !cocheeB) return 1
          if (!cocheeA && cocheeB) return -1

          return 0
        })
      }
    } else if (column === "colCompare") {
      let caseCompare = JSON.parse(
        localStorage["caseCompare" + customNum] ?? "[]"
      )

      if (sens === "up") {
        datatmp.sort((b, a) =>
          caseCompare.includes(a.COPAIP + a.NUNATI) >
          caseCompare.includes(b.COPAIP + b.NUNATI)
            ? 1
            : caseCompare.includes(b.COPAIP + b.NUNATI) >
              caseCompare.includes(a.COPAIP + a.NUNATI)
            ? -1
            : 0
        )
      }
      if (sens === "down") {
        datatmp.sort((a, b) =>
          caseCompare.includes(a.COPAIP + a.NUNATI) >
          caseCompare.includes(b.COPAIP + b.NUNATI)
            ? 1
            : caseCompare.includes(b.COPAIP + b.NUNATI) >
              caseCompare.includes(a.COPAIP + a.NUNATI)
            ? -1
            : 0
        )
      }
    } else if (column === "CORDCG") {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) => {
          let ret = 0
          let a1 = a["CORDCG"]
          let b1 = b["CORDCG"]
          let a2 = a["COFILI"]
          let b2 = b["COFILI"]

          if (a1 && b1) {
            ret = frCollator.compare(b1 ?? "", a1 ?? "")
          } else if (a1 && !b1) ret = 1
          else if (!a1 && b1) ret = -1
          else if (a2 === "O" && b2 !== "O") ret = 1
          else if (a2 !== "O" && b2 === "O") ret = -1

          return ret
        })
      }
      if (sens === "down") {
        datatmp.sort((b, a) => {
          let ret = 0
          let a1 = a["CORDCG"]
          let b1 = b["CORDCG"]
          let a2 = a["COFILI"]
          let b2 = b["COFILI"]

          if (a1 && b1) {
            ret = frCollator.compare(b1 ?? "", a1 ?? "")
          } else if (a1 && !b1) ret = 1
          else if (!a1 && b1) ret = -1
          else if (a2 === "O" && b2 !== "O") ret = 1
          else if (a2 !== "O" && b2 === "O") ret = -1

          return ret
        })
      }
    } else if (column === "COIDEN") {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) => {
          let ret = 0
          let a1 = a["NUECHA_CODEUT"]
          let b1 = b["NUECHA_CODEUT"]
          let a2 = a["COIDEN"]
          let b2 = b["COIDEN"]

          if (a1 && b1) {
            ret = frCollator.compare(b1 ?? "", a1 ?? "")
          } else if (a1 && !b1) ret = 1
          else if (!a1 && b1) ret = -1
          else if (a2 === "O" && b2 !== "O") ret = 1
          else if (a2 !== "O" && b2 === "O") ret = -1

          return ret
        })
      }
      if (sens === "down") {
        datatmp.sort((b, a) => {
          let ret = 0
          let a1 = a["NUECHA_CODEUT"]
          let b1 = b["NUECHA_CODEUT"]
          let a2 = a["COIDEN"]
          let b2 = b["COIDEN"]

          if (a1 && b1) {
            ret = frCollator.compare(b1 ?? "", a1 ?? "")
          } else if (a1 && !b1) ret = 1
          else if (!a1 && b1) ret = -1
          else if (a2 === "O" && b2 !== "O") ret = 1
          else if (a2 !== "O" && b2 === "O") ret = -1

          return ret
        })
      }
    } else if (column === "retyupmh") {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) => {
          let ret = 0
          let a1 = a["retyupmh"]
          let b1 = b["retyupmh"]
          let a2 = a["COCULA"]
          let b2 = b["COCULA"]

          if (a1 && b1) {
            ret = frCollator.compare(b1 ?? "", a1 ?? "")
          } else if (a1 && !b1) ret = 1
          else if (!a1 && b1) ret = -1
          else if (a2 === "O" && b2 !== "O") ret = 1
          else if (a2 !== "O" && b2 === "O") ret = -1

          return ret
        })
      }
      if (sens === "down") {
        datatmp.sort((b, a) => {
          let ret = 0
          let a1 = a["retyupmh"]
          let b1 = b["retyupmh"]
          let a2 = a["COCULA"]
          let b2 = b["COCULA"]

          if (a1 && b1) {
            ret = frCollator.compare(b1 ?? "", a1 ?? "")
          } else if (a1 && !b1) ret = 1
          else if (!a1 && b1) ret = -1
          else if (a2 === "O" && b2 !== "O") ret = 1
          else if (a2 !== "O" && b2 === "O") ret = -1

          return ret
        })
      }
    } else if (column === "retyupsc") {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) => {
          let ret = 0
          let a1 = a["retyupsc"]
          let b1 = b["retyupsc"]
          let a2 = a["COPOLL"]
          let b2 = b["COPOLL"]

          if (a1 && b1) {
            ret = frCollator.compare(b1 ?? "", a1 ?? "")
          } else if (a1 && !b1) ret = 1
          else if (!a1 && b1) ret = -1
          else if (a2 === "O" && b2 !== "O") ret = 1
          else if (a2 !== "O" && b2 === "O") ret = -1

          return ret
        })
      }
      if (sens === "down") {
        datatmp.sort((b, a) => {
          let ret = 0
          let a1 = a["retyupsc"]
          let b1 = b["retyupsc"]
          let a2 = a["COPOLL"]
          let b2 = b["COPOLL"]

          if (a1 && b1) {
            ret = frCollator.compare(b1 ?? "", a1 ?? "")
          } else if (a1 && !b1) ret = 1
          else if (!a1 && b1) ret = -1
          else if (a2 === "O" && b2 !== "O") ret = 1
          else if (a2 !== "O" && b2 === "O") ret = -1

          return ret
        })
      }
    } else if (["retyuppaf", "retyuppro", "retyupbli"].includes(column)) {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) => {
          let ret = 0
          let a1 = a[column]
          let b1 = b[column]
          let a2 = a["COPAFE"]
          let b2 = b["COPAFE"]

          if (a1 && b1) {
            ret = frCollator.compare(b1 ?? "", a1 ?? "")
          } else if (a1 && !b1) ret = 1
          else if (!a1 && b1) ret = -1
          else if (a2 === "O" && b2 !== "O") ret = 1
          else if (a2 !== "O" && b2 === "O") ret = -1

          return ret
        })
      }
      if (sens === "down") {
        datatmp.sort((b, a) => {
          let ret = 0
          let a1 = a[column]
          let b1 = b[column]
          let a2 = a["COPAFE"]
          let b2 = b["COPAFE"]

          if (a1 && b1) {
            ret = frCollator.compare(b1 ?? "", a1 ?? "")
          } else if (a1 && !b1) ret = 1
          else if (!a1 && b1) ret = -1
          else if (a2 === "O" && b2 !== "O") ret = 1
          else if (a2 !== "O" && b2 === "O") ret = -1

          return ret
        })
      }
    } else if (
      column === "CIFNAI" ||
      column === "CCRSEV" ||
      column === "CDMSEV" ||
      column === "CDSSEV" ||
      column === "CFOSSE" ||
      column === "CAVELS" ||
      column === "CALAIT" ||
      column === "COPSEV"
    ) {
      if (sens === "up") {
        datatmp.sort((a, b) => {
          let valA = a[column] ?? "-1"
          let valB = b[column] ?? "-1"
          if (valA === "10+") valA = 11
          if (valA === "10++") valA = 12
          if (valB === "10+") valB = 11
          if (valB === "10++") valB = 12
          if (valA === "") valA = -1
          if (valB === "") valB = -1

          let ret = 0
          if (valA && !valB) ret = 1
          else if (!valA && valB) ret = -1
          else if (+valA > +valB) ret = 1
          else if (+valB > +valA) ret = -1

          return ret
        })
      }
      if (sens === "down") {
        datatmp.sort((b, a) => {
          let valA = a[column] ?? "-1"
          let valB = b[column] ?? "-1"
          if (valA === "10+") valA = 11
          if (valA === "10++") valA = 12
          if (valB === "10+") valB = 11
          if (valB === "10++") valB = 12
          if (valA === "") valA = -1
          if (valB === "") valB = -1

          let ret = 0
          if (valA && !valB) ret = 1
          else if (!valA && valB) ret = -1
          else if (+valA > +valB) ret = 1
          else if (+valB > +valA) ret = -1

          return ret
        })
      }
    } else if (column === "inscrExamMere") {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) => {
          let inscrA = a["inscrMere"]
          let inscrB = b["inscrMere"]
          let coreexA = a["coreex_exa_mere"]
          let coreexB = b["coreex_exa_mere"]
          let examA = a["examMere"]
          let examB = b["examMere"]

          if (inscrA && !inscrB) return 1
          if (!inscrA && inscrB) return -1
          if (inscrA && inscrB)
            return frCollator.compare(inscrA ?? "", inscrB ?? "")

          if (coreexA && !coreexB) return 1
          if (!coreexA && coreexB) return -1
          if (!coreexA && !coreexB) return 0

          if (coreexA === "C" && coreexB === "C")
            return frCollator.compare(examA ?? "", examB ?? "")
          else return frCollator.compare(coreexA ?? "", coreexB ?? "")
        })
      }
      if (sens === "down") {
        datatmp.sort((b, a) => {
          let inscrA = a["inscrMere"]
          let inscrB = b["inscrMere"]
          let coreexA = a["coreex_exa_mere"]
          let coreexB = b["coreex_exa_mere"]
          let examA = a["examMere"]
          let examB = b["examMere"]

          if (inscrA && !inscrB) return 1
          if (!inscrA && inscrB) return -1
          if (inscrA && inscrB)
            return frCollator.compare(inscrA ?? "", inscrB ?? "")

          if (coreexA && !coreexB) return 1
          if (!coreexA && coreexB) return -1
          if (!coreexA && !coreexB) return 0

          if (coreexA === "C" && coreexB === "C")
            return frCollator.compare(examA ?? "", examB ?? "")
          else return frCollator.compare(coreexA ?? "", coreexB ?? "")
        })
      }
    } else if (column === "borexa_coreex") {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) => {
          let aVal = a[column]
          let bVal = b[column]

          if (aVal && a?.inscrAnimal) aVal = ""
          if (bVal && b?.inscrAnimal) bVal = ""

          return frCollator.compare(aVal ?? "", bVal ?? "")
        })
      }
      if (sens === "down") {
        datatmp.sort((b, a) => {
          let aVal = a[column]
          let bVal = b[column]

          if (aVal && a?.inscrAnimal) aVal = ""
          if (bVal && b?.inscrAnimal) bVal = ""

          return frCollator.compare(aVal ?? "", bVal ?? "")
        })
      }
    } else if (column === "pntp") {
      if (sens === "up") {
        datatmp.sort((a, b) => {
          let arrA = (a[column] ?? "").split(" ")
          let valA = arrA[0]
          let arrB = (b[column] ?? "").split(" ")
          let valB = arrB[0]

          if (+valA > +valB) return 1
          if (+valB > +valA) return -1

          if (!arrA[2] && arrB[2]) return 1
          if (arrA[2] && !arrB[2]) return -1

          return 0
        })
      }
      if (sens === "down") {
        datatmp.sort((b, a) => {
          let arrA = (a[column] ?? "").split(" ")
          let valA = arrA[0]
          let arrB = (b[column] ?? "").split(" ")
          let valB = arrB[0]

          if (+valA > +valB) return 1
          if (+valB > +valA) return -1

          if (!arrA[2] && arrB[2]) return 1
          if (arrA[2] && !arrB[2]) return -1

          return 0
        })
      }
    } else if (column === "examAniLIBELO" || column === "examAniLIDETA") {
      const frCollator = new Intl.Collator("fr")

      if (sens === "up") {
        datatmp.sort((a, b) => {
          let valA = ""
          if (a?.borexa_coreex && !a?.inscrAnimal) valA = a[column]
          let valB = ""
          if (b?.borexa_coreex && !b?.inscrAnimal) valB = b[column]

          return frCollator.compare(valA, valB)
        })
      }
      if (sens === "down") {
        datatmp.sort((b, a) => {
          let valA = ""
          if (a?.borexa_coreex && !a?.inscrAnimal) valA = a[column]
          let valB = ""
          if (b?.borexa_coreex && !b?.inscrAnimal) valB = b[column]

          return frCollator.compare(valA, valB)
        })
      }
    } else {
      if (columnsAvailable[column]?.type === "number") {
        if (sens === "up") {
          datatmp.sort((a, b) =>
            +a[column] > +b[column] ? 1 : +b[column] > +a[column] ? -1 : 0
          )
        }
        if (sens === "down") {
          datatmp.sort((b, a) =>
            +a[column] > +b[column] ? 1 : +b[column] > +a[column] ? -1 : 0
          )
        }
      } else if (columnsAvailable[column]?.type === "date") {
        if (sens === "up") {
          datatmp.sort((a, b) => {
            let valA = formatDate4(a[column])
            let valB = formatDate4(b[column])

            return +valA > +valB ? 1 : +valB > +valA ? -1 : 0
          })
        }
        if (sens === "down") {
          datatmp.sort((b, a) => {
            let valA = formatDate4(a[column])
            let valB = formatDate4(b[column])

            return +valA > +valB ? 1 : +valB > +valA ? -1 : 0
          })
        }
      } else {
        const frCollator = new Intl.Collator("fr")

        if (sens === "up") {
          datatmp.sort((a, b) =>
            frCollator.compare(a[column] ?? "", b[column] ?? "")
          )
        }
        if (sens === "down") {
          datatmp.sort((b, a) =>
            frCollator.compare(a[column] ?? "", b[column] ?? "")
          )
        }
      }

      for (let i in clColTmp) {
        if (i === column) continue

        clColTmp[i] = ""
      }
    }

    if (setVisual) setVisualSortColumns(visualColumn, sens, clColTmp)

    return datatmp
  }
  // Method that sorts and apply it to data object
  const applySorColumn = (
    data,
    customNum,
    column,
    visualColumn,
    changeSens = true,
    setVisual = true
  ) => {
    setDataCustom(
      sortColumn({
        data: data,
        customNum: customNum,
        column: column,
        visualColumn: visualColumn,
        changeSens: changeSens,
        setVisual: setVisual,
      })
    )
  }
  // Method to apply visual changes of sort
  const setVisualSortColumns = (column, sens, clColTmp) => {
    if (column === "numtravail") {
      if (sens === "up") {
        setNumTravail("sortDown")
      }
      if (sens === "down") {
        setNumTravail("sortUp")
      }

      for (let i in clColTmp) {
        clColTmp[i] = ""
      }
      setClassColumns(clColTmp)
      setColConfirm("")
      setColDeclar("")
      setColCompare("")
    } else if (column === "colConfirm") {
      if (sens === "up") {
        setColConfirm("sortDown")
      }
      if (sens === "down") {
        setColConfirm("sortUp")
      }

      for (let i in clColTmp) {
        clColTmp[i] = ""
      }
      setClassColumns(clColTmp)

      setNumTravail("")
      setColDeclar("")
      setColCompare("")
    } else if (column === "colDeclar") {
      if (sens === "up") {
        setColDeclar("sortDown")
      }
      if (sens === "down") {
        setColDeclar("sortUp")
      }
      for (let i in clColTmp) {
        clColTmp[i] = ""
      }
      setClassColumns(clColTmp)

      setNumTravail("")
      setColConfirm("")
      setColCompare("")
    } else if (column === "colCompare") {
      if (sens === "up") {
        setColCompare("sortDown")
      }
      if (sens === "down") {
        setColCompare("sortUp")
      }
      for (let i in clColTmp) {
        clColTmp[i] = ""
      }
      setClassColumns(clColTmp)

      setNumTravail("")
      setColConfirm("")
      setColDeclar("")
    } else {
      if (sens === "up") {
        clColTmp[column] = "sortDown"
      }
      if (sens === "down") {
        clColTmp[column] = "sortUp"
      }

      for (let i in clColTmp) {
        if (i === column) continue

        clColTmp[i] = ""
      }

      setNumTravail("")
      setClassColumns(clColTmp)
      setColConfirm("")
      setColDeclar("")
      setColCompare("")
    }
  }
  // Filter data based on searchbar inputs (ask dataType, its called preemptively too!)
  function filterData(value, inv) {
    if (value === "") return sortData(getFilteredInventory(inv), inv.action)
    else {
      let dataToFilter = sortData(getFilteredInventory(inv), inv.action)
      let tmpData = []

      dataToFilter.forEach(data => {
        if (
          (data.COPAIP + data.NUNATI).includes(value) ||
          data.numTravailAnimal.includes(value) ||
          data.NOBOVI.includes(value)
        )
          tmpData.push(data)
      })

      return tmpData
    }
  }
  // Export to XLS function in 3dots menu
  const exportToXLSX = e => {
    e.stopPropagation()

    let export_name = "export_" + customInventory?.name

    // Acquire Data (reference to the HTML table)
    let table_elt = document.getElementById("table-to-xlsx")

    let copyTable = table_elt.cloneNode(true)

    copyTable.children[1].childNodes.forEach(row => {
      row.childNodes.forEach(cell => {
        if (cell.dataset.column === "numtrav") {
          cell.children[0]?.remove()
        }
        if (cell.classList.contains("icone_pdf_certif")) {
          cell.children[0]?.children[0]?.remove()
        }
      })
    })

    // Extract Data (create a workbook object from the table)
    let workbook = XLSX.utils.table_to_book(copyTable, { raw: true })

    let filename = export_name + ".xls"
    if ("ReactNativeWebView" in window) {
      let b64 = XLSX.write(workbook, {
        bookType: "xls",
        bookSST: false,
        type: "base64",
      })

      let message = {
        type: "saveFile",
        content: {
          title: filename,
          b64: "," + b64, // add "," so it works on native app without update to it, after update fix is live(coded but not live atm), the "," wont be needed anymore
          mime: "application/vnd.ms-excel",
        },
      }

      let stringified = JSON.stringify(message)

      window.ReactNativeWebView.postMessage(stringified)
    } else {
      // Package and Release Data (`writeFile` tries to write and save an XLSB file)
      XLSX.writeFile(workbook, filename)
    }

    goBack()
  }
  // Method to save scroll pos in inventaire
  const checkScroll = () => {
    let div_inv = document.getElementById("inventaire_table")
    // Clear our timeout throughout the scroll
    window.clearTimeout(isScrolling)

    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(() => {
      if (div_inv) {
        window.localStorage.setItem("scrollX" + dataType, div_inv.scrollLeft)
        window.localStorage.setItem("scrollY" + dataType, div_inv.scrollTop)
      }
    }, 250)
  }
  // Ask to start date refresh (from the 3dots menu)
  const askRefreshData = e => {
    e.stopPropagation()

    document
      .getElementById("app")
      ?.dispatchEvent(new CustomEvent("refreshData"))

    window.localStorage.needGoStatuDl = "goStatutDl"

    goBack()
  }
  // Get inventory table CSS class
  const getTableClassName = () => {
    let clTable

    clTable = "inventaire-table"

    clTable +=
      isAdherent && listeAnneesCertif.length !== 0 ? "" : " non_adherent"

    clTable +=
      customInventory?.action === "declar" ||
      customInventory?.action === "confirm" ||
      customInventory?.action === "compare"
        ? " cocheInFront"
        : ""

    clTable += customInventory?.action === "declar" ? " plus_bas" : ""

    sidePanel && (clTable += " with_side_filters")

    return `${clTable}`
  }
  const setSeriesLanaud = (value, col) => {
    setFilters(prev => {
      let newFilters = { ...prev }
      newFilters[customInventory.num][col].value = value
      return newFilters
    })
  }
  const getClassAffLigneCertif = () => {
    let cl = "wrapper_aff_ligne_certif"

    isMobile ? (cl += " noscroll") : (cl += " scroll_hover")
    sidePanel && (cl += " with_side_filters")

    return cl
  }
  // Move to "Mes animaux" without onGoing filters
  // to continue search on the whole inventory
  const searchNoFilters = () => {
    setFilters(prev => {
      let newFilters = { ...prev }
      delete newFilters["genealogie"]
      return newFilters
    })
    changeDataType("genealogie")
  }
  ////////////////
  // EXTRA CODE //
  ////////////////
  pageOption === "goconfirm" &&
    customInventory.action !== "confirm" &&
    changeDataType("confirmation")
  pageOption === "declarations" &&
    customInventory.action !== "declar" &&
    changeDataType("lanaud")
  // Empty searchbar if not in search mode to avoid unwanted filtering of inventories
  pageOption !== "search" &&
    searchBar &&
    window.innerWidth < 770 &&
    setSearchBar("")

  return (
    <div
      className={sidePanel ? "inventaire with_side_filters" : "inventaire"}
      id="div_inventaire"
      ref={div}
    >
      {clSatutDL && <ModalStatutDl />}
      {alert && <Alert message={messageAlert} hideAlert={hideAlert} />}
      {confirm && (
        <Confirm
          message={messageConfirm}
          giveConfirm={confirmed => {
            hideConfirm()
            if (confirmed) window.location.hash = `animal|${searchBar}`
          }}
        />
      )}

      {datePicker && (
        <button className={`button_modal_datebg`} onClick={goBack}></button>
      )}
      {clFilter && (
        <>
          <ModalFilter
            inventory={customInventory}
            filters={filters}
            setFilters={setFilters}
            allData={dataCalculated?.Custom}
            nbAnimals={Object.keys(dataCustom).length}
          />
        </>
      )}

      <SideFilters
        sidePanel={sidePanel}
        inventory={customInventory}
        filters={filters}
        setFilters={setFilters}
        allData={dataCalculated?.Custom}
      />

      <div className={`modal_bg_dots_inv ${clDots}`} onClick={goBack}>
        <div id="modal_dots_inv" className={`modal_dots_inv`}>
          <ul>
            <li className="ripple2 pointer" onClick={askRefreshData}>
              Recharger les données
            </li>
            <li
              className="ripple2 pointer"
              onClick={e => {
                e.stopPropagation() // Needed to not proc the goBack from above
                // Coded this way for the effect when menu appears
                window.location.hash = "gestioninv"
              }}
            >
              Gestion des inventaires
            </li>
            <li className="ripple2 pointer" onClick={exportToXLSX}>
              Export XLS
            </li>
          </ul>
        </div>
      </div>
      <div className="inventaire-content">
        <SelectData
          onChange={changeDataType}
          isAdherent={isAdherent}
          inventaire={dataType}
          sidePanel={sidePanel}
        />

        {customInventory.action === "declar" && (
          <div className="wrapper_aff_serie">
            {(() => {
              if (seriesLanaud !== "[]") {
                let listeSeries = JSON.parse(seriesLanaud)
                let serieSelected

                if (filters?.[customInventory.num]?.declarations?.value)
                  serieSelected =
                    filters?.[customInventory.num]?.declarations?.value
                else {
                  serieSelected = Object.keys(listeSeries)[0]
                }

                return (
                  <>
                    <div className="aff_serie">
                      {Object.keys(listeSeries).map(num => (
                        <div
                          key={num}
                          className={
                            num === serieSelected
                              ? "selected_serie pointer ripple2"
                              : "not_selected pointer ripple2"
                          }
                          onClick={() => setSeriesLanaud(num, "declarations")}
                        >
                          {listeSeries[num]?.libelo}
                        </div>
                      ))}
                    </div>

                    <div className="date_series">
                      {`Nés entre le ${formatDate2(
                        listeSeries[serieSelected]?.dadena
                      )} et le ${formatDate2(
                        listeSeries[serieSelected]?.dafina
                      )}`}
                    </div>
                  </>
                )
              }

              return (
                <div className="aff_serie">
                  "Pas de série de déclaration en cours"
                </div>
              )
            })()}
          </div>
        )}

        {isAdherent &&
          listeAnneesCertif.length !== 0 &&
          customInventory.action !== "declar" && (
            <div className={getClassAffLigneCertif()}>
              <p className="text_ligne_certif">
                Afficher les animaux certifiés en{" "}
              </p>
              {listeAnneesCertif.map((annee, index) => {
                if (index > 1 && !displayAllCertif) return

                return (
                  <button
                    key={annee}
                    className="btn_year_certif pointer ripple2"
                    onClick={() => {
                      if (
                        !Object.keys(listeAniByYearCertif).includes(
                          annee.toString()
                        )
                      )
                        doAlert("Aucun animal certifié pour l'année en cours.")
                      else
                        window.location.hash = `inventaire_listeCertifs|${annee}`
                    }}
                  >
                    {annee}
                  </button>
                )
              })}
              {listeAnneesCertif.length > 2 && !displayAllCertif && (
                <button
                  className="btn_year_certif btn_plus pointer ripple2"
                  onClick={() => setDisplayAllCertif(true)}
                >
                  +
                </button>
              )}
              {listeCertifs && (
                <ModalListeCertifs aniCertifs={listeAniByYearCertif} />
              )}
            </div>
          )}

        {letsLoad ? (
          <div className="loader_inv">
            <Loader />
          </div>
        ) : (
          <>
            <div
              ref={div_table}
              id="inventaire_table"
              className={getTableClassName()}
            >
              <CustomInventaire
                setDataCalculated={setDataCalculated}
                customInventory={customInventory}
                dataSorted={dataCustom}
                allData={dataCalculated.Custom}
                sortColumn={applySorColumn}
                numTravail={numTravail}
                colConfirm={colConfirm}
                colDeclar={colDeclar}
                colCompare={colCompare}
                classColums={classColums}
                pageOption={pageOption}
                dataFilliation={dataCalculated.AnalyseFiliation}
                key={customKey}
              />
              {(() => {
                if (dataCustom.length === 0) {
                  let isInFullInventory = false

                  dataCalculated.Custom.forEach(data => {
                    if (
                      searchBar &&
                      ((data.COPAIP + data.NUNATI).includes(searchBar) ||
                        data.numTravailAnimal.includes(searchBar) ||
                        data.NOBOVI.includes(searchBar))
                    )
                      isInFullInventory = true
                  })

                  if (isInFullInventory)
                    return (
                      <div className="message_no_ani">
                        <div className="alert">
                          Aucun animal à l'inventaire ne correspond à votre
                          recherche avec les filtres actuels.
                          <br />
                          Voulez-vous essayer votre recherche sans filtres ?
                        </div>
                        <div className="div_btn_search_no_filter">
                          <button onClick={searchNoFilters}>
                            Rechercher sans filtres
                          </button>
                        </div>
                      </div>
                    )
                  else
                    return (
                      <div className="message_no_ani">
                        <div className="alert">
                          Aucun animal à l'inventaire ne correspond à votre
                          recherche.
                        </div>
                      </div>
                    )
                }
              })()}
            </div>
          </>
        )}
      </div>
      <InventaireFooter
        data={dataCalculated.header}
        nbAnimals={Object.keys(dataCustom).length}
        sidePanel={sidePanel}
      />
    </div>
  )
}

export default Inventaire

// Sort the inventory by a certain value
const sortData = (data, type = null) => {
  let dataTmp = data

  if (Object.prototype.toString.call(dataTmp) !== "[object Array]") return []

  dataTmp.sort((a, b) =>
    a.numTravailAnimal > b.numTravailAnimal
      ? 1
      : b.numTravailAnimal > a.numTravailAnimal
      ? -1
      : 0
  )

  if (type === "confirm") {
    dataTmp.sort((b, a) =>
      a.reponse > b.reponse ? 1 : b.reponse > a.reponse ? -1 : 0
    )
  }

  return data
}
